
import Vue from 'vue2';
import _ from 'lodash';
import 'owl.carousel';
import 'bootstrap';
import 'jquery-ui-dist/jquery-ui'; // this must come after bootstrap

import { Utils } from '../lib/utils.js';
import { StatPrep } from '../x_reports/stat_prep.js';
import { Stats } from '../x_reports/stats.js';
import { editData, parseGameLog } from '../x_reports/data_utils.js';

// vendor CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/vendor/jquery-ui-1.12.1.custom/jquery-ui.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'font-awesome/css/font-awesome.min.css';

// local CSS
import '../../css/coach.less';

var TIMEOUT = 5000;
var SELECTOR_ITEMS = (window.innerWidth >= 585 ? 5 : 3);
var ANALYTICS_PAGE_ROOT = '/COACH/';

StatPrep.init({ editData: editData });

var gameComplete = function(game) {
	if ((game.score.us >= 25 || game.score.them >= 25) && Math.abs(game.score.us - game.score.them) >= 2) {
		return true;
	}
	if (game.num == 5 && (game.score.us >= 15 || game.score.them >= 15) && Math.abs(game.score.us - game.score.them) >= 2) {
		return true;
	}

	var now, then;
	if (game.modified) {
		now = new Date();
		then = new Date(game.modified);
		if ((now - then) / 1000 / 60 / 60 > 1) { // last event more than 1 hour ago?
			return true;
		}
	}

	return false;
};

var displayDate = function(value) {
	var dateTime = new Date(value);
	var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	return month[dateTime.getMonth()] + ' ' + dateTime.getDate() + ', ' + dateTime.getFullYear();
};

var displayDateTime = function(value) {
	var dateTime = new Date(value);
	var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	var dateTimeString = month[dateTime.getMonth()] + ' ' + dateTime.getDate() + ', ' + dateTime.getFullYear();
	dateTimeString += ' ' + dateTime.toLocaleTimeString(navigator.language, {
		hour: '2-digit',
		minute: '2-digit'
	}).replace(/(:\d{2}| )$/, "");
	return dateTimeString;
};

var getJerseyForPlayerNum = function(playernum, players){
	for (var i=0; i < players.length; i++){
		if (players[i].num == playernum){
			return players[i].jersey;
		}
	}
	return playernum;
};

Vue.component('match-view-header', {
	template: `<header :class="'pageHeader ' + smaller">` +
		'<a class="pageHeader-back" href="#" v-on:click="selectMatch">' +
		'<img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/186163/arrow.svg" />' +
		'</a>' +
		'<h1>{{ teamName }}<span v-if="match"> vs {{ match.opponent }}</span></h1>' +
		'</header>',
	props: ['teamName', 'match', 'lastMatches', 'selMatches'],
	methods: {
		selectMatch() {
			eventHub.$emit('clear-match');
		}
	},
	computed: {
		matchDate () {
			if (this.last) {
				return displayDate(this.lastMatches[0].start_dt || this.lastMatches[0].created);
			} else if (this.selMatches.length > 1){
				return '';
			} else {
				if (this.match.start_dt) {
					return displayDateTime(this.match.start_dt);
				} else {
					return displayDate(this.match.created);
				}
			}
		},
		smaller() {
			if (this.match) {
				return 'smaller';
			}
			return '';
		}
	}
});

Vue.component('match-view-confirm', {
	template: '<div id="match-view-confirm" :class="gray">' +
		'<span style="font-weight: bold;">{{ selMatchesTxt1 }}</span><span>{{ selMatchesTxt2 }}</span>' +
		'<img src="/static/img/coach/bluearrow.svg" :class="offscreen"/>' +
		'</div>',
	props: ['current'],
	computed: {
		selMatchesTxt1 () {

			switch (this.current.matchFilter) {
				case 0: return 'All Matches: ';
				case 1: return 'Last Match: ';
				case 2: return 'Last Day: ';
				case 3: return 'Last 2 Days: ';
				case 4: return 'Last 3 Days: ';
				case 5: return 'Last 4 Days: ';
			}

			return '';
		},
		selMatchesTxt2 () {
			if (!this.current.selected_matches.length){
				return '(No matches selected)';
			} else if (this.current.selected_matches.length === 1){
				return 'vs ' + this.current.selected_matches[0].opponent;
			} else {
				return this.current.selected_matches.length + ' matches';
			}
		},
		offscreen () {
			if (!this.current.selected_matches.length){
				return "offscreen";
			}
			return "";
		},
		gray () {
			if (!this.current.selected_matches.length){
				return 'gray';
			}
			return '';
		}
	}
});

Vue.component('match-view-game-select', {
	template: '<div class="pageNav owl-carousel" id="match-view-game-select">' +
		'<a class="pageNav-item is-selected" v-on:click="selectGame(0, $event)"><button class="game-btn btn"><span>Match</span></button></a>' +
		'<a :disabled="!isActive(1)" class="pageNav-item" v-on:click="selectGame(1, $event)"><button class="game-btn btn"><span>Game 1</span></button></a>' +
		'<a :disabled="!isActive(2)" class="pageNav-item" v-on:click="selectGame(2, $event)"><button class="game-btn btn"><span>Game 2</span></button></a>' +
		'<a :disabled="!isActive(3)" class="pageNav-item" v-on:click="selectGame(3, $event)"><button class="game-btn btn"><span>Game 3</span></button></a>' +
		'<a :disabled="!isActive(4)" class="pageNav-item" v-on:click="selectGame(4, $event)"><button class="game-btn btn"><span>Game 4</span></button></a>' +
		'<a :disabled="!isActive(5)" class="pageNav-item" v-on:click="selectGame(5, $event)"><button class="game-btn btn"><span>Game 5</span></button></a>' +
		'</div>',
	props: ['gameNums', 'current'],
	methods: {
		isActive(game_num) {
			return ($.inArray(game_num, this.gameNums) != -1);
		},
		selectGame(game_num, event) {
			if ($(event.currentTarget).attr('disabled')){
				return;
			}
			this.owlCarousel.trigger('to.owl.carousel', $(event.currentTarget).parent().index());
			if (this.isActive(game_num) || game_num === 0) {
				eventHub.$emit('set-game', game_num);
			}

			if (game_num === 0 && this.current.report_style === 0){
				// if we're going from a game to the full match, go to Point Tree in style selector if Game Log is selected style
				$('#match-view-report-style-select a').eq(1).find('button').click();
			}
		}
	},
	mounted() {
		// activate OwlCarousel on select buttons
		this.$nextTick(() => {
			this.owlCarousel = $('#match-view-game-select.owl-carousel').owlCarousel({
				loop:false,
				items:SELECTOR_ITEMS,
				nav:true,
				center:true,
				dots:false,
				startPosition:0
			}).on('changed.owl.carousel', $.proxy((e) => {
				if ($(e.target).hasClass('no-double-dip')){
					$(e.target).removeClass('no-double-dip');
					document.activeElement.blur();
					return false;
				}
				var targetBtn = $(e.target).find(".owl-item").eq(e.item.index).find('.pageNav-item').get(0);
				if ($(targetBtn).attr('disabled') === 'disabled'){
					$(e.target).addClass('no-double-dip');
					this.owlCarousel.trigger('to.owl.carousel', this.gameNums.length);
					return false;
				}
				targetBtn.click();
				$('button').blur();
			}, this));
		});
	}
});

Vue.component('match-view-report-style-select', {
	template: '<div class="pageNav owl-carousel" id="match-view-report-style-select">' +
		'<a class="pageNav-item" v-on:click="selectReportStyle(0, $event)" :disabled="disableIfNotGame"><button class="game-btn btn"><span>Game Log</span></button></a>' +
		'<a class="pageNav-item is-selected" v-on:click="selectReportStyle(1, $event)"><button class="game-btn btn"><span>Point Tree</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportStyle(2, $event)"><button class="game-btn btn"><span>Player Stats</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportStyle(3, $event)"><button class="game-btn btn"><span class="long-label">Rotation (Serve)</span><span class="short-label">Rot.(Serve)</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportStyle(4, $event)"><button class="game-btn btn"><span class="long-label">Rotation (Receive)</span><span class="short-label">Rot.(Rcv)</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportStyle(5, $event)"><button class="game-btn btn"><span>Error Tree</button></a>' +
		'</div>',
	props: ['current'],
	methods: {
		selectReportStyle(style_idx, event) {
			if ($(event.currentTarget).attr('disabled')){
				return;
			}
			//var initial_style = this.current.report_style;
			this.owlCarousel.trigger('to.owl.carousel', $(event.currentTarget).parent().index());
			this.$parent.setReportStyle(style_idx);

			// if we're going to Rotation Stats, and invalid report detail is selected, go to nearest valid detail
			if (style_idx === 3 && this.current.rotation_stat === 2){
				// trigger two clicks in case target is in process of being enabled and the first click fails
				$('#match-view-report-detail-select a').eq(0).find('button').click();
				setTimeout(function(){ $('#match-view-report-detail-select a').eq(0).find('button').click(); }, 300);
			} else if (style_idx === 4 && this.current.rotation_stat === 1){
				$('#match-view-report-detail-select a').eq(1).find('button').click();
				setTimeout(function(){ $('#match-view-report-detail-select a').eq(1).find('button').click(); }, 300);
			} else if ((style_idx === 3 || style_idx === 4) && this.current.rotation_stat === 8){
				// Player Time stat
				$('#match-view-report-detail-select a').eq(6).find('button').click();
				setTimeout(function(){ $('#match-view-report-detail-select a').eq(6).find('button').click(); }, 300);
			} else if ((style_idx === 3 || style_idx === 4) && (this.current.rotation_stat === 10 || this.current.rotation_stat === 11)) {
				// Faults (1) or Faults (2) stats
				$('#match-view-report-detail-select a').eq(8).find('button').click();
				setTimeout(function(){ $('#match-view-report-detail-select a').eq(8).find('button').click(); }, 300);
			}

			// if we're going to Error Tree, swap in the Error Tree Type selector and go to nearest valid detail
			if (style_idx === 5 && this.current.stat > 5){
				$('#match-view-report-detail-select a').eq(4).find('button').click();
				setTimeout(function(){ $('#match-view-report-detail-select a').eq(4).find('button').click(); }, 300);
			}
		}
	},
	computed: {
		disableIfNotGame(){
			return !this.current.game ? 'disabled' : false;
		}
	},
	mounted(){
		// activate OwlCarousel on select buttons
		this.$nextTick(() => {
			this.owlCarousel = $('#match-view-report-style-select.owl-carousel').owlCarousel({
				loop:false,
				items:SELECTOR_ITEMS,
				nav:true,
				center:true,
				dots:false,
				startPosition:1
			}).on('changed.owl.carousel', function(e){
				var targetBtn = $(e.target).find('.owl-item').eq(e.item.index).find('.pageNav-item').get(0);
				if ($(targetBtn).attr('disabled') === 'disabled'){
					targetBtn = $(e.target).find('.owl-item').eq(e.item.index + 1).find('.pageNav-item').get(0);
				}
				targetBtn.click();
				$('button').blur();
			});
		});
	}
});

Vue.component('match-view-report-type-select', {
	template: '<div class="pageNav owl-carousel" id="match-view-report-type-select" :disabled="disableIfNotStats" :style="selectorVisibility">' +
		'<a class="pageNav-item is-selected" v-on:click="selectReportType(0, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Count</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportType(1, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Percent</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportType(2, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Per Game</span></button></a>' +
		'</div>',
	props: ['current'],
	methods: {
		selectReportType(type_idx, event) {
			if ($(event.currentTarget).attr('disabled') === 'disabled'){
				return;
			}

			this.owlCarousel.trigger('to.owl.carousel', $(event.currentTarget).parent().index());
			this.$parent.setReportType(type_idx);

			// if we're going to report type, and invalid stat is selected, go to nearest valid stat
			if (type_idx !== 0 && (this.current.rotation_stat === 10 || this.current.rotation_stat === 11)) {
				// Faults (1) or Faults (2) stats
				$('#match-view-report-detail-select a').eq(8).find('button').click();
				setTimeout(function(){ $('#match-view-report-detail-select a').eq(8).find('button').click(); }, 300);
			}
		}
	},
	computed: {
		disableIfNotStats(){
			return (this.current.report_style !== 2 && this.current.report_style !== 3 && this.current.report_style !== 4) ? 'disabled' : false;
		},
		selectorVisibility(){
			if (this.current.report_style === 5){
				return { display: 'none' };
			}
			return { display: 'block' };
		}
	},
	mounted(){
		// activate OwlCarousel on select buttons
		this.$nextTick(() => {
			this.owlCarousel = $('#match-view-report-type-select.owl-carousel').owlCarousel({
				loop:false,
				items:SELECTOR_ITEMS,
				nav:true,
				center:true,
				dots:false,
				startPosition:0
			}).on('changed.owl.carousel', $.proxy((e) => {
				if ($(e.target).hasClass('no-double-dip')){
					$(e.target).removeClass('no-double-dip');
					document.activeElement.blur();
					return false;
				}
				var targetBtn = $(e.target).find(".owl-item").eq(e.item.index).find('.pageNav-item').get(0);
				if ($(targetBtn).attr('disabled') === 'disabled'){
					$(e.target).addClass('no-double-dip');
					this.owlCarousel.trigger('to.owl.carousel', window.vue.current.report_type);
					return false;
				}
				targetBtn.click();
				$('button').blur();
			}, this));
		});
	}
});


// This component is hidden by default and swapped out with match-view-report-type-select when Error Tree is activated
Vue.component('match-view-error-tree-type-select', {
	template: '<div class="pageNav owl-carousel" id="match-view-error-tree-type-select" :disabled="disableIfNotStats" :style="selectorVisibility">' +
		'<a class="pageNav-item is-selected" v-on:click="selectReportType(0, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Overview</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportType(1, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Team</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportType(2, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Player</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportType(3, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Rotation</span></button></a>' +
		'</div>',
	props: ['current'],
	methods: {
		selectReportType(type_idx, event) {
			if ($(event.currentTarget).attr('disabled') === 'disabled'){
				return;
			}
			this.owlCarousel.trigger('to.owl.carousel', $(event.currentTarget).parent().index());
			this.$parent.setErrorTreeType(type_idx);
		}
	},
	computed: {
		disableIfNotStats(){
			return (this.current.report_style !== 5) ? 'disabled' : false;
		},
		selectorVisibility(){
			if (this.current.report_style === 5){
				return { display: 'block' };
			}
			return { display: 'none' };
		}
	},
	mounted(){
		// activate OwlCarousel on select buttons
		this.$nextTick(() => {
			this.owlCarousel = $('#match-view-error-tree-type-select.owl-carousel').owlCarousel({
				loop:false,
				items:SELECTOR_ITEMS,
				nav:true,
				center:true,
				dots:false,
				startPosition:0
			}).on('changed.owl.carousel', $.proxy((e) => {
				if ($(e.target).hasClass('no-double-dip')){
					$(e.target).removeClass('no-double-dip');
					document.activeElement.blur();
					return false;
				}
				var targetBtn = $(e.target).find(".owl-item").eq(e.item.index).find('.pageNav-item').get(0);
				if ($(targetBtn).attr('disabled') === 'disabled'){
					$(e.target).addClass('no-double-dip');
					this.owlCarousel.trigger('to.owl.carousel', window.vue.current.report_type);
					return false;
				}
				targetBtn.click();
				$('button').blur();
			}, this));
		});
	}
});


Vue.component('match-view-report-detail-select', {
	template: '<div class="pageNav owl-carousel" id="match-view-report-detail-select" :disabled="disableIfNotStats">' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(0, $event)" :disabled="disableIfRotationReceiveStats || disableIfNotStats"><button class="game-btn btn"><span>Serve</span></button></a>' +
		'<a class="pageNav-item is-selected" v-on:click="selectReportDetail(1, $event)" :disabled="disableIfRotationServeStats || disableIfNotStats"><button class="game-btn btn"><span>Receive</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(2, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Attack</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(3, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span>Dig</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(4, $event)" :disabled="disableIfNotStats"><button class="game-btn btn"><span v-if="current.report_style !== 5">Block</span><span v-if="current.report_style === 5">Faults (Totals)</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(5, $event)" :disabled="disableIfNotStatsOrErr"><button class="game-btn btn"><span>Setting</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(6, $event)" :disabled="disableIfNotStatsOrErr"><button class="game-btn btn"><span>FBall</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(7, $event)" :disabled="disableIfRotationReceiveStats || disableIfRotationServeStats || disableIfNotStatsOrErr"><button class="game-btn btn"><span>Player Time</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(8, $event)" :disabled="disableIfNotStatsOrErr"><button class="game-btn btn"><span>Plus/Minus</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(9, $event)" :disabled="disableIfNotPlayerStatsCount"><button class="game-btn btn"><span>Faults (1)</span></button></a>' +
		'<a class="pageNav-item" v-on:click="selectReportDetail(10, $event)" :disabled="disableIfNotPlayerStatsCount"><button class="game-btn btn"><span>Faults (2)</span></button></a>' +
		'</div>',
	props: ['activeAction', 'current'],
	methods: {
		selectReportDetail(detail_idx, event) {
			if ($(event.currentTarget).attr('disabled') === 'disabled'){
				return;
			}
			this.owlCarousel.trigger('to.owl.carousel', $(event.currentTarget).parent().index());
			this.$parent.setReportDetail(detail_idx);
		}
	},
	computed: {
		disableIfNotStats(){
			return ((this.current.report_style === 5 && this.current.error_tree_type === 0) || (this.current.report_style !== 2 && this.current.report_style !== 3 && this.current.report_style !== 4 && this.current.report_style !== 5)) ? 'disabled' : false;
		},
		disableIfNotStatsOrErr(){
			return ((this.current.report_style === 5 && this.current.error_tree_type === 0) || (this.current.report_style !== 2 && this.current.report_style !== 3 && this.current.report_style !== 4)) ? 'disabled' : false;
		},
		disableIfRotationServeStats(){
			return this.current.report_style === 3 ? 'disabled' : false;
		},
		disableIfRotationReceiveStats(){
			return this.current.report_style === 4 ? 'disabled' : false;
		},
		disableIfNotPlayerStatsCount() {
			return (this.current.report_type !== 0 || this.current.report_style !== 2) ? 'disabled' : false;
		},
	},
	mounted(){

		// activate OwlCarousel on select buttons
		this.$nextTick(() => {
			this.owlCarousel = $('#match-view-report-detail-select.owl-carousel').owlCarousel({
				loop:false,
				items:SELECTOR_ITEMS,
				nav:true,
				center:true,
				dots:false,
				startPosition:1
			}).on('changed.owl.carousel', $.proxy((e) => {
				if ($(e.target).hasClass('no-double-dip')){
					$(e.target).removeClass('no-double-dip');
					document.activeElement.blur();
					return false;
				}
				var targetBtn = $(e.target).find(".owl-item").eq(e.item.index).find('.pageNav-item').get(0);
				if ($(targetBtn).attr('disabled') === 'disabled'){
					if (this.current.report_style === 3 && e.item.index === 1 && window.vue.current.rotation_stat === 1){
						this.owlCarousel.trigger('to.owl.carousel', 2);
					} else if (this.current.report_style === 3 && e.item.index === 1 && window.vue.current.rotation_stat > 2){
						this.owlCarousel.trigger('to.owl.carousel', 0);
					} else if (e.item.index === 0){
						this.owlCarousel.trigger('to.owl.carousel', 1);
					} else if (
						(this.current.report_style === 3 || this.current.report_style === 4)
						&& e.item.index === 7
						&& window.vue.current.rotation_stat < 8
					){
						this.owlCarousel.trigger('to.owl.carousel', 8);

					} else if (
						(this.current.report_style === 3 || this.current.report_style === 4)
						&& e.item.index === 7
						&& window.vue.current.rotation_stat > 8
					){
						this.owlCarousel.trigger('to.owl.carousel', 6);

					} else if (this.current.report_style === 5 && e.item.index > 4){
						this.owlCarousel.trigger('to.owl.carousel', 4);
					} else {
						$(e.target).addClass('no-double-dip');
						this.owlCarousel.trigger('to.owl.carousel', window.vue.current.rotation_stat - 1);
					}
					return;
				}
				targetBtn.click();
				$('button').blur();
			}, this));
		});
	}
});



Vue.component('match-view-game-count', {
	template: '<div class="pageNav match-view-game-count">' +
		'<a class="pageNav-item">{{ matchCount }} with {{ gameCount }}</a>' +
		'</div>',
	props: ['current', 'lastMatches', 'matches', 'games'],
	computed: {
		matchCount() {
			var count = '?';
			if (this.current.last) {
				count = this.lastMatches.length;
			} else if (this.current.season) {
				count = this.matches.length;
			} else if (this.current.selected_matches.length) {
				count = this.current.selected_matches.length;
			}
			return count + ' Match' + (count > 1 ? 'es' : '');

		},
		gameCount() {
			var count = this.games.length;
			return count + ' Game' + (count > 1 ? 's' : '');
		}
	}
});

Vue.component('match-view-score', {
	template: `
	<div class="split" id="split-score" style="transform:translateZ(0);">
		<div>
			<div class="split-label">Us</div>
			<div class="split-value">{{ scoreUs }}</div>
			<div class="timeout-display" :class="inactive" v-if="showTimeoutRow">
				<span class="timeoutCircle" :class="{ 'is-used': classTimeouts.us1 }"></span>&nbsp;
				<span class="timeoutCircle" :class="{ 'is-used': classTimeouts.us2 }"></span>
			</div>
		</div>
		<div class="split-center">
			<div>
				<div :class="highlightMatches" v-if="matchCount > 1">{{ matchCount }} Matches</div>
				<div :class="highlightMatches" v-else>{{ matchCount }} Match</div>

				<div :class="highlightGames" v-if="gameCount > 1">{{ gameCount }} Games</div>
				<div :class="highlightGames" v-else>{{ gameCount }} Game</div>
			</div>
			<div class="timeout-display" :class="inactive" v-if="showTimeoutRow">Timeouts</div>
		</div>
		<div>
			<div class="split-label">Them</div>
			<div class="split-value">{{ scoreThem }}</div>
			<div class="timeout-display" :class="inactive" v-if="showTimeoutRow">
				<span class="timeoutCircle" :class="{ 'is-used': classTimeouts.them1 }"></span>&nbsp;
				<span class="timeoutCircle" :class="{ 'is-used': classTimeouts.them2 }"></span>
			</div>
		</div>
	</div>
	`,
	props: ['scoreGames', 'scoreMatches', 'timeouts', 'matchCount', 'gameCount', 'showTimeoutRow', 'scoreType', 'currentGame'],
	computed: {
		scoreUs(){
			if (this.scoreType === 'match'){
				return this.scoreMatches.us;
			} else if (this.scoreType === 'game'){
				return this.scoreGames.us;
			}
		},
		scoreThem(){
			if (this.scoreType === 'match'){
				return this.scoreMatches.them;
			} else if (this.scoreType === 'game'){
				return this.scoreGames.them;
			}
		},
		classTimeouts() {
			if (this.timeouts) {
				return {
					us1: this.timeouts.us.length > 0,
					us2: this.timeouts.us.length > 1,
					them1: this.timeouts.them.length > 0,
					them2: this.timeouts.them.length > 1,
				};
			} else {
				return {
					us1: false,
					us2: false,
					them1: false,
					them2: false,
				};
			}
		},
		inactive(){
			if (!this.timeouts){
				return 'inactive';
			}
			return '';
		},
		highlightGames(){
			if (!this.currentGame && this.scoreType === 'game'){
				return 'highlight';
			}
			return '';
		},
		highlightMatches(){
			if (!this.currentGame && this.scoreType === 'match'){
				return 'highlight';
			}
			return '';
		}
	}
});

Vue.component('match-view-summary-stats', {
	template: '<div class="actionTable" id="actionCount">' +
		'<div class="actionTable-header"><div class="actionTable-count">US</div><div class="actionTable-action">ACTIONS</div><div class="actionTable-count">THEM</div></div>' +
		'<div class="actionTable-scroll">' +
		'<div><div class="actionTable-count">{{ teamKills.us }}</div><div class="actionTable-action">Kills</div><div class="actionTable-count">{{ teamKills.them }}</div></div>' +
		'<div><div class="actionTable-error-action-count">{{ teamOppHitErrors.us }}</div><div class="actionTable-error-action">Opp Hit Err</div><div class="actionTable-error-action-count">{{ teamOppHitErrors.them }}</div></div>' +
		'<div><div class="actionTable-error-action-count">{{ teamOppFreeBallReturnErrors.us }}</div><div class="actionTable-error-action">Opp Free Ball Rtn Err</div><div class="actionTable-error-action-count">{{ teamOppFreeBallReturnErrors.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ teamBlocks.us }}</div><div class="actionTable-action">Blocks</div><div class="actionTable-count">{{ teamBlocks.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ this.stats.us[0].aces }}</div><div class="actionTable-action">Aces</div><div class="actionTable-count">{{ stats.them[0].aces }}</div></div>' +
		'<div><div class="actionTable-error-action-count">{{ this.stats.them[0].serve_errors }}</div><div class="actionTable-error-action">Opp Serve Err</div><div class="actionTable-error-action-count">{{ this.stats.us[0].serve_errors }}</div></div>' +
		'<div><div class="actionTable-error-action-count">{{ teamOppFaults.us }}</div><div class="actionTable-error-action">Opp Faults</div><div class="actionTable-error-action-count">{{ teamOppFaults.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ this.stats.us[0].us_adjustment }}</div><div class="actionTable-action">Adjustments</div><div class="actionTable-count">{{ this.stats.them[0].them_adjustment }}</div></div>' +
		'<div><div class="actionTable-count">{{ this.stats.us[0].pass_rating }}</div><div class="actionTable-action">Pass</div><div class="actionTable-count">{{ this.stats.them[0].pass_rating }}</div></div>' +
		'<div><div class="actionTable-count">{{ this.stats.us[0].side_out_percent }}</div><div class="actionTable-action">SO%</div><div class="actionTable-count">{{ this.stats.them[0].side_out_percent }}</div></div>' +
		'<div><div class="actionTable-count">{{ this.stats.us[0].pt_score_percent }}</div><div class="actionTable-action">PS%</div><div class="actionTable-count">{{ this.stats.them[0].pt_score_percent }}</div></div>' +
		'</div>' +
		'</div>',
	props: ['stats'],
	computed: {
		teamKills() {
			var team_kills = {
				us: this.stats.us[0].combined_kills + this.stats.them[0].errors_whb + this.stats.them[0].errors_fbp,
				them: this.stats.them[0].combined_kills + this.stats.us[0].errors_whb + this.stats.us[0].errors_fbp,
			};
			return team_kills;
		},
		teamBlocks() {
			var team_blocks = {
				us: this.stats.us[0].block_solos + (this.stats.us[0].block_assists / 2),
				them: this.stats.them[0].earned_blck,
			};
			return team_blocks;
		},
		teamOppHitErrors() {
			var team_opp_hit_errors = {
				us: this.stats.them[0].combined_kill_errors_minus_block_details,
				them: this.stats.us[0].combined_kill_errors_minus_block_details,
			};
			return team_opp_hit_errors;
		},
		teamOppFreeBallReturnErrors() {
			var team_opp_fbr_errors = {
				us: this.stats.them[0].errors_fbp + this.stats.them[0].errors_2br + this.stats.them[0].errors_3br,
				them: this.stats.us[0].errors_fbp + this.stats.us[0].errors_2br + this.stats.us[0].errors_3br,
			};
			return team_opp_fbr_errors;
		},
		teamOppFaults() {
			var team_opp_faults = {
				us: this.stats.them[0].faults_cnt + this.stats.them[0].errors_set
					- this.stats.them[0].faults_bra - this.stats.them[0].faults_foot,

				them: this.stats.us[0].faults_cnt + this.stats.us[0].errors_set
					- this.stats.us[0].faults_bra - this.stats.us[0].faults_foot,
			};
			return team_opp_faults;
		},
	}
});

Vue.component('match-view-error-tree', {
	template: '<div class="actionTable" id="error-tree">' +
		'<div class="actionTable-header"><div class="actionTable-count">US</div><div class="actionTable-action">ACTIONS</div><div class="actionTable-count">THEM</div></div>' +

		// Overview
		'<div class="actionTable-scroll" v-if="current.error_tree_type === 0">' +
		'<div><div class="actionTable-count errtree-header">{{ totalErr.us }}</div><div class="actionTable-action errtree-header">Total Errors</div><div class="actionTable-count errtree-header">{{ totalErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalAttackErr.us }}</div><div class="actionTable-action">Attack Errors</div><div class="actionTable-action-count">{{ totalAttackErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalServeErr.us }}</div><div class="actionTable-action">Serve Errors</div><div class="actionTable-action-count">{{ totalServeErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalRcvErr.us }}</div><div class="actionTable-action">Receive Errors</div><div class="actionTable-action-count">{{ totalRcvErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalDigErr.us }}</div><div class="actionTable-action">Dig Errors</div><div class="actionTable-count">{{ totalDigErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalBlockErr.us }}</div><div class="actionTable-action">Block Errors</div><div class="actionTable-count">{{ totalBlockErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalFBPErr.us }}</div><div class="actionTable-action">Free Ball Errors</div><div class="actionTable-count">{{ totalFBPErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalSetErr.us }}</div><div class="actionTable-action">Set Errors</div><div class="actionTable-count">{{ totalSetErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalMiscErr.us }}</div><div class="actionTable-action">Misc Errors</div><div class="actionTable-count">{{ totalMiscErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ totalFaults.us }}</div><div class="actionTable-action">Faults</div><div class="actionTable-action-count">{{ totalFaults.them }}</div></div>' +
		'</div>' +

		// Team
		'<div class="actionTable-scroll" v-if="current.error_tree_type === 1 && current.stat < 5">' +
		'<div><div class="actionTable-count errtree-header">{{ teamTotalErr.us }}</div><div class="actionTable-action errtree-header">Total Errors</div><div class="actionTable-count errtree-header">{{ teamTotalErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ teamNetErr.us }}</div><div class="actionTable-action">Net</div><div class="actionTable-action-count">{{ teamNetErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ teamOutErr.us }}</div><div class="actionTable-action">Out</div><div class="actionTable-action-count">{{ teamOutErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ teamBlockedErr.us }}</div><div class="actionTable-action">Blocked</div><div class="actionTable-count">{{ teamBlockedErr.them }}</div></div>' +
		'</div>' +

		// Team Faults
		'<div class="actionTable-scroll" v-if="current.error_tree_type === 1 && current.stat === 5">' +
		'<div><div class="actionTable-count errtree-header">{{ teamTotalErr.us }}</div><div class="actionTable-action errtree-header">Total Faults</div><div class="actionTable-count errtree-header">{{ teamTotalErr.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ faults.bra.us }}</div><div class="actionTable-action">Back Row Attack</div><div class="actionTable-action-count">{{ faults.bra.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ faults.bhndl.us }}</div><div class="actionTable-action">Ball Handling</div><div class="actionTable-action-count">{{ faults.bhndl.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ faults.foot.us }}</div><div class="actionTable-action">Foot</div><div class="actionTable-action-count">{{ faults.foot.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ faults.net.us }}</div><div class="actionTable-action">Net</div><div class="actionTable-action-count">{{ faults.net.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ faults.oor.us }}</div><div class="actionTable-action">Out of Rotation</div><div class="actionTable-action-count">{{ faults.oor.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ faults.otn.us }}</div><div class="actionTable-action">Over the Net</div><div class="actionTable-action-count">{{ faults.otn.them }}</div></div>' +
		'<div><div class="actionTable-count">{{ faults.undr.us }}</div><div class="actionTable-action">Under</div><div class="actionTable-action-count">{{ faults.undr.them }}</div></div>' +
		'<div v:if="faults.unspecified.us > 0 || faults.unspecified.them > 0"><div class="actionTable-count">{{ faults.unspecified.us }}</div><div class="actionTable-action">Unspecified</div><div class="actionTable-action-count">{{ faults.unspecified.them }}</div></div>' +
		'</div>' +

		// Player
		'<div class="actionTable-scroll" v-if="current.error_tree_type === 2">' +
		'<div><div class="actionTable-count errtree-header">{{ teamTotalErr.us }}</div><div class="actionTable-action errtree-header">Total Errors</div><div class="actionTable-count errtree-header">{{ teamTotalErr.them }}</div></div>' +
		'<div is="error-tree-player-row" v-for="stats in playerStats.byRow" :stats="stats" :current="current"></div>' +
		'</div>' +

		// Rotation
		'<div class="actionTable-scroll" v-if="current.error_tree_type === 3">' +
		'<div><div class="actionTable-count errtree-header">{{ teamTotalErr.us }}</div><div class="actionTable-action errtree-header">Total Errors</div><div class="actionTable-count errtree-header">{{ teamTotalErr.them }}</div></div>' +
		'<div is="error-tree-rotation-row" v-for="rotation in rotations" :rotation="rotation" :our-srv-stats="ourSrvRotationStats[rotation-1]" :their-srv-stats="theirSrvRotationStats[rotation-1]" :current="current"></div>' +
		'</div>' +


		'</div>',
	props: ['summaryStats', 'playerStats', 'ourSrvRotationStats', 'theirSrvRotationStats', 'current'],
	computed: {
		rotations() {
			let rotations = [];
			for (let i=1; i < this.ourSrvRotationStats.length; i++){
				rotations.push(i);
			}
			return rotations;
		},
		faults() {
			return {
				bra : {
					us: this.summaryStats.us[0].faults_bra,
					them: this.summaryStats.them[0].faults_bra
				},
				bhndl : {
					us: this.summaryStats.us[0].faults_bhndl,
					them: this.summaryStats.them[0].faults_bhndl
				},
				foot : {
					us: this.summaryStats.us[0].faults_foot,
					them: this.summaryStats.them[0].faults_foot
				},
				net : {
					us: this.summaryStats.us[0].faults_net,
					them: this.summaryStats.them[0].faults_net
				},
				oor : {
					us: this.summaryStats.us[0].faults_oor,
					them: this.summaryStats.them[0].faults_oor
				},
				otn : {
					us: this.summaryStats.us[0].faults_otn,
					them: this.summaryStats.them[0].faults_otn
				},
				undr : {
					us: this.summaryStats.us[0].faults_undr,
					them: this.summaryStats.them[0].faults_undr
				},
				unspecified : {
					us: this.summaryStats.us[0].faults_fault,
					them: this.summaryStats.them[0].faults_fault
				}
			};
		},
		totalErr() {
			var usKills = this.summaryStats.us[0].earned_spike +  this.summaryStats.us[0].earned_tip +
				this.summaryStats.us[0].earned_dump + this.summaryStats.us[0].earned_dbh;
			var themKills = this.summaryStats.them[0].earned_spike +  this.summaryStats.them[0].earned_tip +
				this.summaryStats.them[0].earned_dump + this.summaryStats.them[0].earned_dbh;
			var total = {
				us: this.summaryStats.us[0].errors_cnt + this.summaryStats.us[0].faults_cnt
					+ this.summaryStats.them[0].earned_blck + themKills,

				// NOTE: this.summaryStats.them[0].serve_rcv_errors are not included in
				//       this.summaryStats.them[0].errors_cnt for unknown reasons. Adding here
				//       so totalErr adds up correctly, but there may be a better place to do this.
				them: this.summaryStats.them[0].errors_cnt + this.summaryStats.them[0].faults_cnt
					+ this.summaryStats.them[0].serve_rcv_errors + this.summaryStats.us[0].earned_blck + usKills,
			};
			return total;
		},
		totalAttackErr() {
			var attack = {
				us: this.summaryStats.us[0].combined_kill_errors + this.summaryStats.them[0].earned_blck,
				them: this.summaryStats.them[0].combined_kill_errors + this.summaryStats.us[0].earned_blck,
			};
			return attack;
		},
		totalServeErr() {
			var serve = {
				us: this.summaryStats.us[0].serve_errors,
				them: this.summaryStats.them[0].serve_errors,
			};
			return serve;
		},
		totalRcvErr() {
			var rcv = {
				us: this.summaryStats.us[0].serve_rcv_errors,
				them: this.summaryStats.them[0].serve_rcv_errors,
			};
			return rcv;
		},
		totalDigErr() {
			var usKills = this.summaryStats.us[0].earned_spike +  this.summaryStats.us[0].earned_tip +
				this.summaryStats.us[0].earned_dump + this.summaryStats.us[0].earned_dbh;
			var themKills = this.summaryStats.them[0].earned_spike +  this.summaryStats.them[0].earned_tip +
				this.summaryStats.them[0].earned_dump + this.summaryStats.them[0].earned_dbh;
			var dig = {
				// counting all Kills by Us as Dig Errors for Them and vice versa
				us: this.summaryStats.us[0].errors_dig + themKills,
				them: this.summaryStats.them[0].errors_dig + usKills,
			};
			return dig;
		},
		totalBlockErr() {
			var block = {
				us: this.summaryStats.us[0].errors_blck,
				them: this.summaryStats.them[0].errors_blck || '--',
			};
			return block;
		},
		totalFBPErr() {
			var fbp = {
				us: this.summaryStats.us[0].errors_fbp,
				them: this.summaryStats.them[0].errors_fbp || '--',
			};
			return fbp;
		},
		totalSetErr() {
			var set = {
				us: this.summaryStats.us[0].errors_set,
				them: this.summaryStats.them[0].errors_set || '--',
			};
			return set;
		},
		totalMiscErr() {
			var fbp = {
				us: this.summaryStats.us[0].errors_whb + this.summaryStats.us[0].errors_2br
					+ this.summaryStats.us[0].errors_3br,

				them: this.summaryStats.them[0].errors_whb + this.summaryStats.them[0].errors_2br
					+ this.summaryStats.them[0].errors_3br
			};
			return fbp;
		},
		totalFaults() {
			var faults = {
				us: this.summaryStats.us[0].faults_cnt - this.summaryStats.us[0].faults_bra - this.summaryStats.us[0].faults_foot,
				them: this.summaryStats.them[0].faults_cnt - this.summaryStats.them[0].faults_bra - this.summaryStats.them[0].faults_foot,
			};
			return faults;
		},
		teamTotalErr() {
			var total = { us: '--', them: '--' };
			if (this.current.stat === 1){
				// Serve
				total = {
					us: this.summaryStats.us[0].serve_errors,
					them: this.summaryStats.them[0].serve_errors,
				};
			} else if (this.current.stat === 2){
				// Receive
				total = {
					us: this.summaryStats.us[0].serve_rcv_errors,
					them: this.summaryStats.them[0].serve_rcv_errors,
				};
			} else if (this.current.stat === 3){
				// Attack
				total = {
					us: this.summaryStats.us[0].combined_kill_errors,
					them: this.summaryStats.them[0].combined_kill_errors,
				};
			} else if (this.current.stat === 4){
				// Dig
				total = {
					us: this.summaryStats.us[0].errors_dig,
					them: this.summaryStats.them[0].errors_dig,
				};
			} else if (this.current.stat > 4){
				// Faults
				total = {
					us: this.summaryStats.us[0].faults_cnt,
					them: this.summaryStats.them[0].faults_cnt,
				};
			}
			return total;
		},
		teamNetErr() {
			var total = { us: '--', them: '--' };
			if (this.current.stat === 1){
				total = {
					us: this.summaryStats.us[0].serve_error_detail_net,
					them: '--',
				};
			} else if (this.current.stat === 3){
				total = {
					us: this.summaryStats.us[0].attack_error_detail_net,
					them: '--',
				};
			} else {
				total = {
					us: '--',
					them: '--',
				};
			}
			return total;
		},
		teamOutErr() {
			var total = { us: '--', them: '--' };
			if (this.current.stat === 1){
				total = {
					us: this.summaryStats.us[0].serve_error_detail_out,
					them: '--',
				};
			} else if (this.current.stat === 3){
				total = {
					us: this.summaryStats.us[0].attack_error_detail_out,
					them: '--',
				};
			} else {
				total = {
					us: '--',
					them: '--',
				};
			}
			return total;
		},
		teamBlockedErr() {
			var total = { us: '--', them: '--' };
			if (this.current.stat === 3){
				total = {
					us: this.summaryStats.us[0].attack_error_detail_blocked,
					them: '--',
				};
			} else {
				total = {
					us: '--',
					them: '--',
				};
			}
			return total;
		},
	}
});


Vue.component('error-tree-player-row', {
	template: `
		<div :style="hidden">
			<div class="actionTable-count">{{ rowStat.us }}</div>
			<div class="actionTable-action">{{ label }}</div>
			<div class="actionTable-action-count">{{ rowStat.them }}</div>
		</div>',
	`,
	props: ['stats', 'current'],
	computed: {
		rowStat() {
			var total = { us: '--', them: '--' };
			if (this.current.stat === 1){
				total.us = this.stats.us.errors.srv;
			} else if (this.current.stat === 2){
				total.us = this.stats.us.errors.rcv;
			} else if (this.current.stat === 3){
				total.us = this.stats.us.errors.spk + this.stats.us.errors.tip + this.stats.us.errors.dbh
					+ this.stats.us.errors.dump + this.stats.us.faults.bra + this.stats.us.errors.whb;
			} else if (this.current.stat === 4){
				total.us = this.stats.us.errors.dig;
			} else if (this.current.stat > 4){
				total.us = this.stats.us.faults.cnt;
			}
			return total;
		},
		label(){
			return this.stats.player_name;
		},
		hidden(){
			if (this.stats.player_name === '(team)'){
				return { display: 'none' };
			}
			return { display: 'flex' };
		}
	}
});


Vue.component('error-tree-rotation-row', {
	template: '<div><div class="actionTable-count">{{ rowStat.us }}</div><div class="actionTable-action">Rotation {{ rotation }}</div><div class="actionTable-action-count">{{ rowStat.them }}</div></div>',
	props: ['ourSrvStats', 'theirSrvStats', 'current', 'rotation'],
	computed: {
		rowStat() {
			var total = { us: '--', them: '--' };
			if (this.current.stat === 1){
				total.us = this.ourSrvStats.errors_srv;
			} else if (this.current.stat === 2){
				total.us = this.theirSrvStats.pass_errors;
			} else if (this.current.stat === 3){
				total.us = this.ourSrvStats.combined_kill_errors + this.theirSrvStats.combined_kill_errors;
			} else if (this.current.stat === 4){
				total.us = this.ourSrvStats.dig_errors + this.theirSrvStats.dig_errors;
			} else if (this.current.stat > 4){
				total.us = this.ourSrvStats.faults_cnt + this.theirSrvStats.faults_cnt;
			}
			return total;
		}
	}
});

Vue.component('match-view-stats-select', {
	template: '<div class="stat-selector">' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 1 }" v-on:click="changeStat(2)">Serve</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 2 }" v-on:click="changeStat(3)">Receive</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 3 }" v-on:click="changeStat(4)">Attack</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 4 }" v-on:click="changeStat(5)">Dig</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 5 }" v-on:click="changeStat(6)">Block</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 6 }" v-on:click="changeStat(7)">Setting</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 7 }" v-on:click="changeStat(8)">FBall</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 8 }" v-on:click="changeStat(9)">Player Time</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.stat === 9 }" v-on:click="changeStat(1)">+/-</button>' +
		'</div>',
	props: ['current'],
	methods: {
		changeStat(id) {
			this.current.stat = id;
		},
		isActive(id) {
			return this.current.stat === id;
		}
	},
});

Vue.component('match-view-player', {
	template: '<div v-if="stats.player_name !== \'(team)\'" :class="{ \'bold\': stats.player_name === \'TOTAL\'}">' +
		'<div>{{ player_name }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0">{{ stats.serve_attempts }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0">{{ stats.aces }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0">{{ stats.serve_errors }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0">{{ stats.serve_rating }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1">{{ stats.percent_of_team_serve_attempts }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1">{{ stats.ace_percent }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1">{{ stats.serve_error_percent }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1">{{ stats.pt_score_percent }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 2">{{ stats.serve_attempts_per_game }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 2">{{ stats.aces_per_game }}</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 2">{{ stats.serve_errors_per_game }}</div>' +

		'<div v-if="current.stat === 2 && current.report_type === 0">{{ stats.pass_attempts }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 0">{{ threepass }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 0">{{ stats.serve_rcv_errors }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 0">{{ stats.pass_rating }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 1">{{ stats.percent_of_team_rcv_attempts }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 1">{{ threepass_percent }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 1">{{ stats.pass_error_percent }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 2">{{ stats.pass_attempts_per_game }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 2">{{ threepass_per_game }}</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 2">{{ pass_errors_per_game }}</div>' +

		'<div v-if="current.stat === 3 && current.report_type === 0">{{ stats.combined_kill_attempts }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 0">{{ stats.combined_kills }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 0">{{ stats.combined_kill_errors }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 0">{{ stats.hitting_efficiency }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 1">{{ stats.percent_of_team_kill_attempts }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 1">{{ stats.combined_kill_percent }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 1">{{ stats.combined_kill_error_percent }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 2">{{ stats.combined_kill_attempts_per_game }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 2">{{ stats.combined_kills_per_game }}</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 2">{{ stats.combined_kill_errors_per_game }}</div>' +

		'<div v-if="current.stat === 4 && current.report_type === 0">{{ stats.dig_attempts }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 0">{{ stats.digs }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 0">{{ stats.dig_errors }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 1">{{ stats.percent_of_team_dig_attempts }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 1">{{ stats.dig_percent }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 1">{{ stats.dig_error_percent }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 2">{{ stats.dig_attempts_per_game }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 2">{{ stats.digs_per_game }}</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 2">{{ stats.dig_errors_per_game }}</div>' +

		'<div v-if="current.stat === 5 && current.report_type === 0">{{ stats.block_attempts }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 0">{{ stats.blocks }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 0">{{ stats.block_errors }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 0">{{ stats.net_blocks }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 1">{{ stats.percent_of_team_block_attempts }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 1">{{ stats.block_percent }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 1">{{ stats.block_error_percent }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 2">{{ stats.block_attempts_per_game }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 2">{{ stats.blocks_per_game }}</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 2">{{ stats.block_errors_per_game }}</div>' +

		'<div v-if="current.stat === 6 && current.report_type === 0">{{ stats.assists_as_setter }}</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 0">{{ stats.assists_as_non_setter }}</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 1 && stats.player_name !== \'TOTAL\'">{{ stats.percent_of_team_assists_as_setter }}</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 1 && stats.player_name !== \'TOTAL\'">{{ stats.percent_of_team_assists_as_non_setter }}</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 1 && stats.player_name === \'TOTAL\'">{{ stats.assists_as_setter_percent }}</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 1 && stats.player_name === \'TOTAL\'">{{ stats.assists_as_non_setter_percent }}</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 2">{{ stats.assists_as_setter_per_game }}</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 2">{{ stats.assists_as_non_setter_per_game }}</div>' +

		'<div v-if="current.stat === 7 && current.report_type === 0">{{ stats.fbp_attempts }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 0">{{ threefbp }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 0">{{ stats.fbp_errors }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 0">{{ stats.fbp_rating }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 1">{{ stats.percent_of_team_fbp_attempts }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 1">{{ threefbp_percent }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 1">{{ stats.fbp_error_percent }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 2">{{ stats.fbp_attempts_per_game }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 2">{{ threefbp_per_game }}</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 2">{{ fbp_errors_per_game }}</div>' +

		'<div v-if="current.stat === 8 && current.report_type === 0">{{ stats.games_played }}</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 0">{{ stats.points_played }}</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 0">{{ stats.games_played_as_setter }}</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 1">{{ stats.games_played_percent }}</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 1">{{ stats.points_played_percent }}</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 2">{{ stats.points_played_per_game }}</div>' +

		'<div v-if="current.stat === 9 && current.report_type === 0">{{ stats.earned_cnt }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 0">{{ stats.lost_cnt }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 0">{{ stats.net_eef_cnt }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 1">{{ stats.percent_of_team_points_earned }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 1">{{ stats.percent_of_team_points_lost }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 1">{{ stats.percent_of_team_points_net }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 2">{{ stats.earned_cnt_per_game }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 2">{{ stats.lost_cnt_per_game }}</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 2">{{ stats.eef_cnt_per_game }}</div>' +

		'<div v-if="current.stat === 10 && current.report_type === 0">{{ stats.faults_bra }}</div>' +
		'<div v-if="current.stat === 10 && current.report_type === 0">{{ stats.faults_bhndl }}</div>' +
		'<div v-if="current.stat === 10 && current.report_type === 0">{{ stats.faults_foot }}</div>' +
		'<div v-if="current.stat === 10 && current.report_type === 0">{{ stats.faults_oor }}</div>' +

		'<div v-if="current.stat === 11 && current.report_type === 0">{{ stats.faults_net }}</div>' +
		'<div v-if="current.stat === 11 && current.report_type === 0">{{ stats.faults_otn }}</div>' +
		'<div v-if="current.stat === 11 && current.report_type === 0">{{ stats.faults_undr }}</div>' +

		'</div>',
	props: ['stats', 'current'],
	computed: {
		player_name(){
			if (this.stats.player_name === 'TOTAL'){
				if (this.current.stat === 6 && this.current.report_type === 1){
					return '%\xa0OF\xa0TOTAL\xa0SETS';
				}
				return this.stats.player_name;
			}
			var splitName = this.stats.player_name.split(' ');
			var name = splitName[0];
			if (splitName.length > 1){
				name += '\xa0' + splitName[1][0];
			}
			return name;
		},
		// these are necessary because Vue doesn't like properies with slashes or starting with a number
		threefbp_percent(){
			return this.stats['3fbp_percent'];
		},
		threepass_percent(){
			return this.stats['3pass_percent'];
		},
		threefbp(){
			return this.stats['3fbp'];
		},
		threepass(){
			return this.stats['3pass'];
		},
		threepass_per_game(){
			return this.stats['perfect/game'];
		},
		threefbp_per_game(){
			return this.stats['perfect_fbp/game'];
		},
		pass_errors_per_game(){
			return this.stats['pass_errors/game'];
		},
		fbp_errors_per_game(){
			return this.stats['fbp_errors/game'];
		}
	}
});

Vue.component('match-view-player-stats', {
	template: '<div id="playerStats">' +
		'<div class="playerStats-header">' +
		'<div v-on:click="sortColumn(\'player_name\')">Name</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0" v-on:click="sortColumn(\'serve_attempts\')">Attpt</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0" v-on:click="sortColumn(\'aces\')">Aces</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0" v-on:click="sortColumn(\'serve_errors\')">Errors</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 0" v-on:click="sortColumn(\'serve_rating\')">Srv Rating</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_serve_attempts\')">Team Attpt&nbsp;%</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1" v-on:click="sortColumn(\'ace_percent\')">Ace&nbsp;%</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1" v-on:click="sortColumn(\'serve_error_percent\')">Error&nbsp;%</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 1" v-on:click="sortColumn(\'pt_score_percent\')">PS&nbsp;%</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 2" v-on:click="sortColumn(\'serve_attempts_per_game\')">Attpt&nbsp;/G</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 2" v-on:click="sortColumn(\'aces_per_game\')">Aces&nbsp;/G</div>' +
		'<div v-if="current.stat === 1 && current.report_type === 2" v-on:click="sortColumn(\'serve_errors_per_game\')">Errors&nbsp;/G</div>' +

		'<div v-if="current.stat === 2 && current.report_type === 0" v-on:click="sortColumn(\'pass_attempts\')">Attpt</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 0" v-on:click="sortColumn(\'3pass\')">3-pass</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 0" v-on:click="sortColumn(\'serve_rcv_errors\')">Errors</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 0" v-on:click="sortColumn(\'pass_rating\')">Pass Rating</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_rcv_attempts\')">Team Attpt&nbsp;%</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 1" v-on:click="sortColumn(\'3pass_percent\')">3-pass&nbsp;%</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 1" v-on:click="sortColumn(\'pass_error_percent\')">Error&nbsp;%</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 2" v-on:click="sortColumn(\'pass_attempts_per_game\')">Attpt&nbsp;/G</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 2" v-on:click="sortColumn(\'perfect/game\')">3-pass&nbsp;/G</div>' +
		'<div v-if="current.stat === 2 && current.report_type === 2" v-on:click="sortColumn(\'pass_errors/game\')">Errors&nbsp;/G</div>' +

		'<div v-if="current.stat === 3 && current.report_type === 0" v-on:click="sortColumn(\'combined_kill_attempts\')">Attpt</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 0" v-on:click="sortColumn(\'combined_kills\')">Kills</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 0" v-on:click="sortColumn(\'combined_kill_errors\')">Errors</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 0" v-on:click="sortColumn(\'hitting_efficiency\')">Hit Eff</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_kill_attempts\')">Team Attpt&nbsp;%</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 1" v-on:click="sortColumn(\'combined_kill_percent\')">Kill&nbsp;%</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 1" v-on:click="sortColumn(\'combined_kill_error_percent\')">Error&nbsp;%</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 2" v-on:click="sortColumn(\'combined_kill_attempts_per_game\')">Attpt&nbsp;/G</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 2" v-on:click="sortColumn(\'combined_kills_per_game\')">Kills&nbsp;/G</div>' +
		'<div v-if="current.stat === 3 && current.report_type === 2" v-on:click="sortColumn(\'combined_kill_errors_per_game\')">Errors&nbsp;/G</div>' +

		'<div v-if="current.stat === 4 && current.report_type === 0" v-on:click="sortColumn(\'dig_attempts\')">Attpt</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 0" v-on:click="sortColumn(\'digs\')">Digs</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 0" v-on:click="sortColumn(\'dig_errors\')">Err</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_dig_attempts\')">Team Attpt&nbsp;%</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 1" v-on:click="sortColumn(\'dig_percent\')">Dig&nbsp;%</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 1" v-on:click="sortColumn(\'dig_error_percent\')">Error&nbsp;%</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 2" v-on:click="sortColumn(\'dig_attempts_per_game\')">Attpt&nbsp;/G</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 2" v-on:click="sortColumn(\'digs_per_game\')">Digs&nbsp;/G</div>' +
		'<div v-if="current.stat === 4 && current.report_type === 2" v-on:click="sortColumn(\'dig_errors_per_game\')">Errors&nbsp;/G</div>' +

		'<div v-if="current.stat === 5 && current.report_type === 0" v-on:click="sortColumn(\'block_attempts\')">Attpt</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 0" v-on:click="sortColumn(\'blocks\')">Blocks</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 0" v-on:click="sortColumn(\'block_errors\')">Error</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 0" v-on:click="sortColumn(\'net_blocks\')">Net</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_block_attempts\')">Team Attpt&nbsp;%</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 1" v-on:click="sortColumn(\'block_percent\')">Block&nbsp;%</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 1" v-on:click="sortColumn(\'block_error_percent\')">Error&nbsp;%</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 2" v-on:click="sortColumn(\'block_attempts_per_game\')">Attpt&nbsp;/G</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 2" v-on:click="sortColumn(\'blocks_per_game\')">Blocks&nbsp;/G</div>' +
		'<div v-if="current.stat === 5 && current.report_type === 2" v-on:click="sortColumn(\'block_errors_per_game\')">Errors&nbsp;/G</div>' +

		'<div v-if="current.stat === 6 && current.report_type === 0" v-on:click="sortColumn(\'assists_as_setter\')">Asst Setter</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 0" v-on:click="sortColumn(\'assists_as_non_setter\')">Asst Non-Setter</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_assists_as_setter\')">Asst Setter&nbsp;%</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_assists_as_non_setter\')">Asst Non-Setter&nbsp;%</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 2" v-on:click="sortColumn(\'assists_as_setter_per_game\')">Asst Setter&nbsp;/G</div>' +
		'<div v-if="current.stat === 6 && current.report_type === 2" v-on:click="sortColumn(\'assists_as_non_setter_per_game\')">Asst Non-Setter&nbsp;/G</div>' +

		'<div v-if="current.stat === 7 && current.report_type === 0" v-on:click="sortColumn(\'fbp_attempts\')">Attpt</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 0" v-on:click="sortColumn(\'3fbp\')">3-FB Pass</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 0" v-on:click="sortColumn(\'fbp_errors\')">Err</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 0" v-on:click="sortColumn(\'fbp_rating\')">Rating</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_fbp_attempts\')">Team Attpt&nbsp;%</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 1" v-on:click="sortColumn(\'3fbp_percent\')">3-FB Pass&nbsp;%</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 1" v-on:click="sortColumn(\'fbp_error_percent\')">Error&nbsp;%</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 2" v-on:click="sortColumn(\'fbp_attempts_per_game\')">Attpt&nbsp;/G</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 2" v-on:click="sortColumn(\'perfect_fbp/game\')">3-FB Pass&nbsp;/G</div>' +
		'<div v-if="current.stat === 7 && current.report_type === 2" v-on:click="sortColumn(\'fbp_errors/game\')">Errors&nbsp;/G</div>' +

		'<div v-if="current.stat === 8 && current.report_type === 0" v-on:click="sortColumn(\'games_played\')">Games Played</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 0" v-on:click="sortColumn(\'points_played\')">Points Played</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 0" v-on:click="sortColumn(\'games_played_as_setter\')">Games Played as Setter</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 1" v-on:click="sortColumn(\'games_played_percent\')">Games Played&nbsp;%</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 1" v-on:click="sortColumn(\'points_played_percent\')">Points Played&nbsp;%</div>' +
		'<div v-if="current.stat === 8 && current.report_type === 2" v-on:click="sortColumn(\'points_played_per_game\')">Points Played&nbsp;/G</div>' +

		'<div v-if="current.stat === 9 && current.report_type === 0" v-on:click="sortColumn(\'earned_cnt\')">Points Earned</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 0" v-on:click="sortColumn(\'lost_cnt\')">Points Lost</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 0" v-on:click="sortColumn(\'net_eef_cnt\')">Points Net</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_points_earned\')">Team Pts Earned&nbsp;%</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_points_lost\')">Team Pts Lost&nbsp;%</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 1" v-on:click="sortColumn(\'percent_of_team_points_net\')">Net Points&nbsp;%</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 2" v-on:click="sortColumn(\'earned_cnt_per_game\')">Earned&nbsp;/G</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 2" v-on:click="sortColumn(\'lost_cnt_per_game\')">Lost&nbsp;/G</div>' +
		'<div v-if="current.stat === 9 && current.report_type === 2" v-on:click="sortColumn(\'eef_cnt_per_game\')">Total&nbsp;/G</div>' +

		'<div v-if="current.stat === 10 && current.report_type === 0" v-on:click="sortColumn(\'faults_bra\')">Back Row Attack</div>' +
		'<div v-if="current.stat === 10 && current.report_type === 0" v-on:click="sortColumn(\'faults_bhndl\')">Ball Handling</div>' +
		'<div v-if="current.stat === 10 && current.report_type === 0" v-on:click="sortColumn(\'faults_foot\')">Foot Fault</div>' +
		'<div v-if="current.stat === 10 && current.report_type === 0" v-on:click="sortColumn(\'faults_oor\')">Out of Rotation</div>' +

		'<div v-if="current.stat === 11 && current.report_type === 0" v-on:click="sortColumn(\'faults_net\')">Net</div>' +
		'<div v-if="current.stat === 11 && current.report_type === 0" v-on:click="sortColumn(\'faults_otn\')">Over the Net</div>' +
		'<div v-if="current.stat === 11 && current.report_type === 0" v-on:click="sortColumn(\'faults_undr\')">Under the Net</div>' +

		'</div>' +
		'<div class="playerStats-scroll">' +
		'<div is="match-view-player" v-for="stats in completeStats" :stats="stats" :current="current"></div>' +
		'</div>' +
		'</div>',
	props: ['stats', 'current', 'games'],
	computed: {
		completeStats() {
			var inRally = {
				pass: true,
				hit: true,
				dig: true
			};
			var columns = [
				'player_num',
				'player_name',
				'games_played',
				'points_played',
				'earned_cnt_per_game',
				'errors_cnt_per_game',
				'faults_cnt_per_game',
				'eef_cnt_per_game',
				'serve_attempts',
				'pt_score_percent',
				'serve_error_percent',
				'ace_percent',
				'pass_attempts',
				'pass_rating',
				'3pass_percent',
				'pass_error_percent',
				'combined_kill_attempts',
				'hitting_efficiency',
				'combined_kill_percent',
				'combined_kill_error_percent',
				'combined_kills_per_game',
				'combined_kill_attempts_per_game',
				'digs',
				'dig_errors',
				'dig_percent',
				'dig_attempts_per_game',
				'block_errors',
				'blocks',
				'net_blocks',
				'block_percent',
				'block_error_percent',
				'blocks_per_game',
				'games_played_as_setter',
				'assists_as_non_setter',
				'assists_as_non_setter_per_game',
				'assists_as_setter',
				'assists_as_setter_per_game',
				'fbp_attempts',
				'fbp_rating',
				'3fbp_percent',
				'fbp_error_percent',
				'points_earned',
				'points_lost',
				'points_net',
				'percent_of_team_points_earned',
				'percent_of_team_points_lost',
				'percent_of_team_points_net',
				'lost_cnt_per_game',
				'aces',
				'serve_errors',
				'percent_of_team_serve_attempts',
				'serve_attempts_per_game',
				'aces_per_game',
				'serve_errors_per_game',
				'3pass',
				'serve_rcv_errors',
				'percent_of_team_rcv_attempts',
				'pass_attempts_per_game',
				'perfect/game',
				'pass_errors/game',
				'combined_kills',
				'combined_kill_errors',
				'percent_of_team_kill_attempts',
				'combined_kill_errors_per_game',
				'dig_attempts',
				'percent_of_team_dig_attempts',
				'dig_error_percent',
				'digs_per_game',
				'dig_attempts_per_game',
				'block_attempts',
				'percent_of_team_block_attempts',
				'block_attempts_per_game',
				'block_errors_per_game',
				'assists_as_setter_percent',
				'assists_as_non_setter_percent',
				'percent_of_team_assists_as_setter',
				'percent_of_team_assists_as_non_setter',
				'3fbp',
				'fbp_errors',
				'percent_of_team_fbp_attempts',
				'fbp_attempts_per_game',
				'perfect_fbp/game',
				'fbp_errors/game',
				'games_played_percent',
				'points_played_percent',
				'points_played_per_game',
				'earned_cnt',
				'lost_cnt',
				'net_eef_cnt',
				'faults_net',
				'faults_undr',
				'faults_bhndl',
				'faults_foot',
				'faults_bra',
				'faults_otn',
				'faults_oor',
			];
			var statsComplete = Stats.calc(this.stats.byRow, columns, {
				blockAssistsHalfValue: false,
				who: "us",
				agg: true,
				inRally: inRally
			});

			// add stats that can't be calculated by Stats module -- TODO: extract to function
			var us_score = 0;
			var them_score = 0;
			if (this.current.game) {
				us_score = this.current.game.score.us;
				them_score = this.current.game.score.them;
			} else if (this.games.length) {
				_.forEach(this.games, function(game) {
					us_score += game.score.us;
					them_score += game.score.them;
					game.num = parseInt(game.num);
				});
			}

			// Team Total
			var summaryData = Stats.calc([this.stats.agg], columns, {
				blockAssistsHalfValue: false,
				who: "us",
				agg: true,
				inRally: inRally
			});
			summaryData[0].player_name = "TOTAL";

			for (var i=0; i < statsComplete.length; i++){
				statsComplete[i]['percent_of_team_points_earned'] = ((statsComplete[i]['earned_cnt'] / summaryData[0]['earned_cnt']) * 100).toFixed(1) + '%';
				if (statsComplete[i]['percent_of_team_points_earned'] === 'Infinity%'){
					statsComplete[i]['percent_of_team_points_earned'] = '--';
				}
				statsComplete[i]['percent_of_team_points_lost'] = ((statsComplete[i]['lost_cnt'] / summaryData[0]['lost_cnt']) * 100).toFixed(1) + '%';
				if (statsComplete[i]['percent_of_team_points_lost'] === 'Infinity%'){
					statsComplete[i]['percent_of_team_points_lost'] = '--';
				}
				statsComplete[i]['percent_of_team_points_net'] = (((statsComplete[i]['earned_cnt'] / summaryData[0]['earned_cnt']) - (statsComplete[i]['lost_cnt'] / summaryData[0]['lost_cnt'])) * 100).toFixed(1) + '%';
				if (statsComplete[i]['percent_of_team_points_net'] === 'Infinity%'){
					statsComplete[i]['percent_of_team_points_net'] = '--';
				}
				statsComplete[i]['percent_of_team_serve_attempts'] = ((statsComplete[i]['serve_attempts'] / summaryData[0]['serve_attempts']) * 100).toFixed(1) + '%';
				statsComplete[i]['percent_of_team_rcv_attempts'] = ((statsComplete[i]['pass_attempts'] / summaryData[0]['pass_attempts']) * 100).toFixed(1) + '%';
				statsComplete[i]['percent_of_team_kill_attempts'] = ((statsComplete[i]['combined_kill_attempts'] / summaryData[0]['combined_kill_attempts']) * 100).toFixed(1) + '%';
				statsComplete[i]['percent_of_team_dig_attempts'] = ((statsComplete[i]['dig_attempts'] / summaryData[0]['dig_attempts']) * 100).toFixed(1) + '%';
				statsComplete[i]['percent_of_team_block_attempts'] = ((statsComplete[i]['block_attempts'] / summaryData[0]['block_attempts']) * 100).toFixed(1) + '%';
				statsComplete[i]['percent_of_team_fbp_attempts'] = ((statsComplete[i]['fbp_attempts'] / summaryData[0]['fbp_attempts']) * 100).toFixed(1) + '%';
				statsComplete[i]['percent_of_team_assists_as_setter'] = ((statsComplete[i]['assists_as_setter'] / summaryData[0]['assists_as_setter']) * 100).toFixed(1) + '%';
				if (statsComplete[i]['percent_of_team_assists_as_setter'] === 'NaN%'){
					statsComplete[i]['percent_of_team_assists_as_setter'] = '--';
				}
				statsComplete[i]['percent_of_team_assists_as_non_setter'] = ((statsComplete[i]['assists_as_non_setter'] / summaryData[0]['assists_as_non_setter']) * 100).toFixed(1) + '%';
				if (statsComplete[i]['percent_of_team_assists_as_non_setter'] === 'NaN%'){
					statsComplete[i]['percent_of_team_assists_as_non_setter'] = '--';
				}
				statsComplete[i]['games_played_percent'] = ((statsComplete[i]['games_played'] / this.games.length) * 100).toFixed(1) + '%';
				statsComplete[i]['points_played_percent'] = ((statsComplete[i]['points_played'] / (us_score + them_score)) * 100).toFixed(1) + '%';
			}
			summaryData[0]['percent_of_team_points_earned'] = '100%';
			summaryData[0]['percent_of_team_points_lost'] = '100%';
			summaryData[0]['percent_of_team_points_net'] = '0%';
			summaryData[0]['percent_of_team_serve_attempts'] = '100%';
			summaryData[0]['percent_of_team_rcv_attempts'] = '100%';
			summaryData[0]['percent_of_team_kill_attempts'] = '100%';
			summaryData[0]['percent_of_team_dig_attempts'] = '100%';
			summaryData[0]['percent_of_team_block_attempts'] = '100%';
			summaryData[0]['percent_of_team_fbp_attempts'] = '100%';
			summaryData[0]['games_played_percent'] = '--';
			summaryData[0]['points_played_percent'] = '--';

			var column = this.current.stat_sort;
			var order = this.current.stat_order;
			var result;
			if (column === 'player_name') {
				result = _.sortBy(statsComplete, [column]);
			} else {
				// sort by column value with null values (output as string '-') sorted after numbers
				result = _.sortBy(statsComplete, [function(o){ return o[column] === '-' ? -100000 : parseFloat(o[column]); }]);
			}

			result = order > 0 ? result : result.reverse();

			// add Team Total row to the end
			result.push(summaryData[0]);

			return result;
		}
	},
	methods: {
		sortColumn(column) {
			this.current.stat_sort = column;
			this.current.stat_order = this.current.stat_order * -1;
		}
	}
});

Vue.component('match-view-rotation-select', {
	template: '<div class="stat-selector">' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.rotation_stat === 1 }" v-on:click="changeRotationStat(1)">Serve</button>' +
		'<button class="stat-btn btn" :class="{ \'stat-btn-sel\': current.rotation_stat === 2 }" v-on:click="changeRotationStat(2)">Serve Receive</button>' +
		'</div>',
	props: ['current'],
	methods: {
		changeRotationStat(id) {
			this.current.rotation_stat = id;
		},
		isActive(id) {
			return this.current.rotation_stat === id;
		}
	},
});

Vue.component('match-view-rotation', {
	template: '<div :class="{ \'bold\': rotation == totalRotations }">' +
		'<div v-if="rotation < totalRotations">{{ rotation }}</div>' +
		'<div v-else>TOTAL</div>' +

		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">{{ stats.serve_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">{{ stats.aces }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">{{ stats.serve_errors }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">{{ stats.serve_rating }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">{{ stats.percent_of_team_serve_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">{{ stats.ace_percent }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">{{ stats.serve_error_percent }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">{{ stats.pt_score_percent }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 2">{{ stats.serve_attempts_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 2">{{ stats.aces_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 2">{{ stats.serve_errors_per_game }}</div>' +

		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">{{ stats.serve_rcv }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">{{ threepass }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">{{ stats.serve_rcv_errors }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">{{ stats.pass_rating }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">{{ stats.percent_of_team_rcv_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">{{ threepass_percent }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">{{ stats.serve_rcv_error_percent }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">{{ stats.side_out_percent }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 2">{{ stats.pass_attempts_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 2">{{ threepass_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 2">{{ stats.serve_rcv_errors_per_game }}</div>' +

		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">{{ stats.combined_kill_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">{{ stats.combined_kills }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">{{ stats.combined_kill_errors }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">{{ stats.hitting_efficiency }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 1">{{ stats.percent_of_team_kill_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 1">{{ stats.combined_kill_percent }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 1">{{ stats.combined_kill_error_percent }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 2">{{ stats.combined_kill_attempts_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 2">{{ stats.combined_kills_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 2">{{ stats.combined_kill_errors_per_game }}</div>' +

		'<div v-if="current.rotation_stat === 4 && current.report_type === 0">{{ stats.dig_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 0">{{ stats.digs }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 0">{{ stats.dig_errors }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 1">{{ stats.percent_of_team_dig_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 1">{{ stats.dig_percent }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 1">{{ stats.dig_error_percent }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 2">{{ stats.dig_attempts_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 2">{{ stats.digs_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 2">{{ stats.dig_errors_per_game }}</div>' +

		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">{{ stats.block_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">{{ stats.blocks }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">{{ stats.block_errors }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">{{ stats.net_blocks }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 1">{{ stats.percent_of_team_block_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 1">{{ stats.block_percent }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 1">{{ stats.block_error_percent }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 2">{{ stats.block_attempts_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 2">{{ stats.blocks_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 2">{{ stats.block_errors_per_game }}</div>' +

		'<div v-if="current.rotation_stat === 6 && current.report_type === 0">{{ stats.assists_as_setter }}</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 0">{{ stats.assists_as_non_setter }}</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 1">{{ stats.assists_as_setter_percent }}</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 1">{{ stats.assists_as_non_setter_percent }}</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 2">{{ stats.assists_as_setter_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 2">{{ stats.assists_as_non_setter_per_game }}</div>' +

		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">{{ stats.fbp_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">{{ threefbp }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">{{ stats.fbp_errors }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">{{ stats.fbp_rating }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 1">{{ stats.percent_of_team_fbp_attempts }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 1">{{ threefbp_percent }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 1">{{ stats.fbp_error_percent }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 2">{{ stats.fbp_attempts_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 2">{{ threefbp_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 2">{{ fbp_errors_per_game }}</div>' +

		'<div v-if="current.rotation_stat === 9 && current.report_type === 0">{{ stats.points_earned }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 0">{{ stats.points_lost }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 0">{{ stats.points_net }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 1">{{ stats.percent_of_team_points_earned }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 1">{{ stats.percent_of_team_points_lost }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 1">{{ stats.percent_of_team_points_net }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 2">{{ stats.earned_cnt_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 2">{{ stats.lost_cnt_per_game }}</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 2">{{ stats.eef_cnt_per_game }}</div>' +

		'</div>',
	props: ['rotation', 'current', 'stats', 'totalRotations'],
	computed: {
		// these are necessary because Vue doesn't like properies with slashes or starting with a number
		threefbp_percent(){
			return this.stats['3fbp_percent'];
		},
		threepass_percent(){
			return this.stats['3pass_percent'];
		},
		threefbp(){
			return this.stats['3fbp'];
		},
		threepass(){
			return this.stats['3pass'];
		},
		threepass_per_game(){
			return this.stats['perfect/game'];
		},
		threefbp_per_game(){
			return this.stats['perfect_fbp/game'];
		},
		pass_errors_per_game(){
			return this.stats['pass_errors/game'];
		},
		fbp_errors_per_game(){
			return this.stats['fbp_errors/game'];
		}
	}
});

Vue.component('match-view-rotation-stats', {
	template: '<div id="rotationStats">' +
		'<div class="rotationStats-header">' +
		'<div>Rot #</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">Attpt</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">Aces</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">Error</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 0">Rating</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">Attpt&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">Ace&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">Err&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 1">PS&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 2">Attpt&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 2">Aces&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 1 && current.report_type === 2">Err&nbsp;/G</div>' +

		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">Serves Rec\'d</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">3-Pass</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">Got Aced</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 0">Pass Rating</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">Attpt&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">3-Pass&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">Err&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 1">SO&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 2">Attpt&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 2">3-Pass&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 2 && current.report_type === 2">Err&nbsp;/G</div>' +

		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">Attpt</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">Kills</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">Error</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 0">Rating</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 1">Attpt&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 1">Kill&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 1">Err&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 2">Attpt&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 2">Aces&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 3 && current.report_type === 2">Err&nbsp;/G</div>' +

		'<div v-if="current.rotation_stat === 4 && current.report_type === 0">Attpt</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 0">Digs</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 0">Error</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 1">Attpt&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 1">Dig&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 1">Err&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 2">Attpt&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 2">Digs&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 4 && current.report_type === 2">Err&nbsp;/G</div>' +

		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">Attpt</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">Blocks</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">Error</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 0">Net Blocks</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 1">Attpt&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 1">Block&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 1">Err&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 2">Attpt&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 2">Blocks&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 5 && current.report_type === 2">Err&nbsp;/G</div>' +

		'<div v-if="current.rotation_stat === 6 && current.report_type === 0">Asst Setter</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 0">Asst Non-Setter</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 1">Asst Setter&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 1">Asst Non-Setter&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 2">Asst Setter&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 6 && current.report_type === 2">Asst Non-Setter&nbsp;%</div>' +

		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">Attpt</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">FB Passes</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">Error</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 0">Rating</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 1">Attpt&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 1">3-FBP&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 1">Err&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 2">Attpt&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 2">3-FBP&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 7 && current.report_type === 2">Err&nbsp;/G</div>' +

		'<div v-if="current.rotation_stat === 9 && current.report_type === 0">Points Earned</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 0">Points Lost</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 0">Points Net</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 1">Team Pts Earned&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 1">Team Pts Lost&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 1">Net Points&nbsp;%</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 2">Earned&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 2">Lost&nbsp;/G</div>' +
		'<div v-if="current.rotation_stat === 9 && current.report_type === 2">Net&nbsp;/G</div>' +

		'</div>' +
		'<div class="rotationStats-scroll" v-show="!(current.rotation_stat === 1 && current.report_style === 4) && !(current.rotation_stat === 2 && current.report_style === 3)">' +
		'<div is="match-view-rotation" v-for="rotation in rotations" :rotation="rotation" :current="current" :stats="stats[rotation-1]" :total-rotations="stats.length"></div>' +

		'</div>' +
		'</div>',
	props: ['stats', 'current'],
	computed: {
		rotations() {
			let rotations = [];
			for (let i=1; i <= this.stats.length; i++){
				// final row is Total
				rotations.push(i);
			}
			return rotations;
		},
		// these are necessary because Vue doesn't like properies with slashes or starting with a number
		threefbp_percent(){
			return this.stats['3fbp_percent'];
		},
		threepass_percent(){
			return this.stats['3pass_percent'];
		},
		threefbp(){
			return this.stats['3fbp'];
		},
		threepass(){
			return this.stats['3pass'];
		},
		threepass_per_game(){
			return this.stats['perfect/game'];
		},
		threefbp_per_game(){
			return this.stats['perfect_fbp/game'];
		},
		pass_errors_per_game(){
			return this.stats['pass_errors/game'];
		},
		fbp_errors_per_game(){
			return this.stats['fbp_errors/game'];
		}
	}
});

Vue.component('game-log-entry', {
	template: '<tr v-if="regularLog" :class="{ highlight: isCurrentPlayer }"><td class="gameLog-action">{{ action }}</td><td class="gameLog-player">{{ playerName }}</td><td class="gameLog-jersey">#{{ jerseyNumber }}</td><td class="gameLog-score"><span :class="badge">{{ score }}</span></td></tr>' +
		'<tr v-else><td colspan="4" class="gameLog-timeout">{{ action }}</td></tr>',
	props: ['entry', 'currentPlayerNum', 'players'],
	computed: {
		isCurrentPlayer() {
			return this.currentPlayerNum && this.entry.playernum === this.currentPlayerNum;
		},
		action () {
			if (this.entry.action_type_log === 'timeout') {
				return 'Timeout for ' + this.entry.action_log;
			} else if (this.entry.action_type_log === 'substitution') {
				var substitution = this.entry['action_log'].split(' ');
				return 'Sub #' + getJerseyForPlayerNum(substitution[1], this.players) + ' for #' + getJerseyForPlayerNum(substitution[3], this.players);
			} else if (this.entry.action_log) {
				if (this.entry.action_type_log === 'errors' || this.entry.action_type_log === 'faults') {
					return this.entry.action_log + ' - Error';
				} else {
					return this.entry.action_log;
				}
			} else {
				return '???';
			}
		},
		playerName() {
			if (this.entry.playername){
				return this.entry.playername;
			} else if (!this.entry.partial){
				return '(Opponent)';
			} else {
				return '???';
			}
		},
		jerseyNumber() {
			if (this.entry.playernum) {
				var player = _.find(this.players, ['num', this.entry.playernum]);
				if (player) {
					return player.jersey || player.num;
				} else {
					return this.entry.playernum;
				}
			} else {
				return '-';
			}
		},
		score() {
			return this.entry.score_log || '';
		},
		badge() {
			if (this.entry.point_to_log === 'us') {
				return 'badge is-us';
			} else if (this.entry.point_to_log === 'them') {
				return 'badge is-them';
			} else {
				return '';
			}
		},
		regularLog() {
			return this.entry.point_to_log;
		}
	}
});

Vue.component('match-view-game-log', {
	template: '<table class="gameLog" id="gameLog"><thead><tr><th class="gameLog-action">Action</th><th class="gameLog-player">Player</th><th class="gameLog-jersey">Jersey</th><th class="gameLog-score">Score</th></tr></thead><tbody>' +
		'<tr is=game-log-entry v-for="entry in processedGameLog" :entry="entry" :current-player-num="currentPlayerNum" :players="players"></tr>' +
		'</tbody></table>',
	props: ['gameId', 'gameLog', 'players', 'currentPlayerNum'],
	computed: {
		processedGameLog() {
			var processed_game_log = StatPrep.processPointLogRows(this.gameLog, {
				type: 'game',
				list: [this.gameId]
			}, this.players);
			return processed_game_log.byRow.reverse();
		}
	}
});

Vue.component('match-view', {
	template: '<div id="match-view-wrap">' +
		'<match-view-header :match="current.match" :team-name="teamName" :last-matches="lastMatches" :sel-matches="current.selected_matches"></match-view-header>' +
		'<div id="match-view-inner-wrap" v-if="hasGames">' +
		'<match-view-score v-on:click.native="toggleScoreView" :score-games="scoreGames" :score-matches="scoreMatches" :timeouts="timeouts" :match-count="matchCount" :game-count="gameCount" :show-timeout-row="showGameView" :score-type="scoreType" :current-game="current.game"></match-view-score>' +
		'<div id="carousel-selector-outer-wrap">' +
		'<div id="carousel-selector-wrap" :class="closed">' +
		'<match-view-game-select :game-nums="gameNums" :current="current" v-if="showGameView"></match-view-game-select>' +
		'<match-view-report-style-select :current="current"></match-view-report-style-select>' +
		'<match-view-report-type-select :current="current"></match-view-report-type-select>' +
		'<match-view-error-tree-type-select :current="current"></match-view-error-tree-type-select>' +
		'<match-view-report-detail-select :current="current"></match-view-report-detail-select>' +
		'</div>' +
		'<div class="pageNav" id="carousel-selector-toggle" v-on:click="toggleSelectors">' +
		'<span v-if="current.carouselSelectorOpen">Hide Options</span><span v-else>Show Options</span>' +
		'<div class="carousel-selector-toggle-arrow" :class="arrowdir"><img src="/static/img/coach/bluearrow.svg"></div>' +
		'</div>' +
		'</div>' +
		`<div id="carousel-selector-summary" :class="'pageNav ' + showSummary"></div>` +
		'<div id="match-data-wrap">' +
		'<match-view-game-log v-show="current.report_style === 0" v-if="current.game" :game-id="current.game.id" :game-log="parsedGameLog" :players="current.team.players" :current-player-num="currentPlayerNum"></match-view-game-log>' +
		'<match-view-summary-stats v-show="current.report_style === 1" :stats="summaryStats" :current="current"></match-view-summary-stats>' +
		'<match-view-player-stats v-show="current.report_style === 2" :stats="playerStats" :current="current" :games="games"></match-view-player-stats>' +
		'<match-view-rotation-stats v-show="current.report_style === 3 || current.report_style === 4" :stats="rotationStats" :current="current"></match-view-rotation-stats>' +
		'<match-view-error-tree v-show="current.report_style === 5" :summary-stats="summaryStats" :player-stats="playerStats" :our-srv-rotation-stats="rotationStats" :their-srv-rotation-stats="theirSrvRotationStats" :current="current"></match-view-error-tree>' +
		'</div>' +
		'</div>' +
		'<div class="initializing" id="loading-match">' +
		'<i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;L O A D I N G &nbsp;&nbsp; D A T A . . .' +
		'</div>' +
		'</div>',
	props: ['current', 'matches', 'games', 'lastMatches'],
	methods: {
		playerNames() {
			var playerNames = {};
			_.forEach(this.current.team.players, function(player) {
				playerNames[player.num] = player.name;
			});
			return playerNames;
		},
		setReportStyle(style_idx) {
			this.current.report_style = style_idx;
			$('#match-view-report-style-select .pageNav-item').removeClass('is-selected');
			$('#match-view-report-style-select .pageNav-item').addClass(function(index) {
				return index === style_idx ? 'is-selected' : null;
			});
		},
		setReportType(type_idx) {
			this.current.report_type = type_idx;
			$('#match-view-report-type-select .pageNav-item').removeClass('is-selected');
			$('#match-view-report-type-select .pageNav-item').addClass(function(index) {
				return index === type_idx ? 'is-selected' : null;
			});
		},
		setReportDetail(detail_idx) {
			this.current.stat = detail_idx + 1;
			this.current.rotation_stat = detail_idx + 1;
			$('#match-view-report-detail-select .pageNav-item').removeClass('is-selected');
			$('#match-view-report-detail-select .pageNav-item').addClass(function(index) {
				return index === detail_idx ? 'is-selected' : null;
			});
		},
		setErrorTreeType(type_idx) {
			this.current.error_tree_type = type_idx;
			$('#match-view-error-tree-type-select .pageNav-item').removeClass('is-selected');
			$('#match-view-error-tree-type-select .pageNav-item').addClass(function(index) {
				return index === type_idx ? 'is-selected' : null;
			});
		},
		toggleSelectors(){
			var txt = '';
			if (this.current.carouselSelectorOpen){
				$('#carousel-selector-wrap .pageNav-item.is-selected:not([disabled]) span:visible').each(function(){ txt += $(this).text() + ' • '; });
				txt = txt.slice(0, -3);
			}
			$('#carousel-selector-summary').html(txt);
			return eventHub.$emit('toggle-carousel-open');
		},
		toggleScoreView(){
			if (this.current.game){
				return;
			}
			eventHub.$emit('toggle-score-view');
		},
		getRotationStats(forceReceiving){
			if (this.current.match || this.games.length) {
				var inRally = {
					pass: true,
					hit: true,
					dig: true
				};
				var games = this.current.game ? [this.current.game.id] : _.map(this.games, 'id');
				var logs = this.current.game ? this.parsedGameLog : this.parsedMatchGameLogs;
				var list = ['1:us', '2:us', '3:us', '4:us', '5:us', '6:us'];
				if (this.current.report_style === 4 || forceReceiving){
					list = ['1:them', '2:them', '3:them', '4:them', '5:them', '6:them'];
				}
				var rotationStatsPrep = StatPrep.processStatsIteration(logs, {
					type: 'rot_serv',
					list: list
				}, {
					type: 'game',
					list: games
				});

				var stat_columns = [
					'serve_attempts',
					'serve_rating',
					'aces',
					'serve_errors',
					'points_lost_on_serve',
					'points_scored_on_serve',
					'pt_score_percent',
					'serve_error_percent',
					'side_out_percent',
					'serve_rcv',
					'serve_rcv_errors',
					'serve_rcv_error_percent',
					'pass_rating',
					'points_scored_on_rcv',
					'points_lost_on_rcv',
					'percent_of_team_serve_attempts',
					'ace_percent',
					'serve_attempts_per_game',
					'aces_per_game',
					'serve_errors_per_game',
					'pass_attempts',
					'serve_rcv_errors_per_game',
					'percent_of_team_rcv_attempts',
					'3pass_percent',
					'serve_rcv_error_percent',

					'combined_kill_attempts',
					'combined_kills',
					'combined_kill_errors',
					'hitting_efficiency',
					'percent_of_team_kill_attempts',
					'combined_kill_percent',
					'combined_kill_error_percent',
					'combined_kill_attempts_per_game',
					'combined_kills_per_game',
					'combined_kill_errors_per_game',

					'dig_attempts',
					'digs',
					'dig_errors',
					'percent_of_team_dig_attempts',
					'dig_percent',
					'dig_error_percent',
					'dig_attempts_per_game',
					'digs_per_game',
					'dig_errors_per_game',

					'assists_as_setter',
					'assists_as_non_setter',
					'assists_as_setter_percent',
					'assists_as_non_setter_percent',
					'assists_as_setter_per_game',
					'assists_as_non_setter_per_game',

					'fbp_attempts',
					'3fbp',
					'fbp_errors',
					'fbp_rating',
					'percent_of_team_fbp_attempts',
					'3fbp_percent',
					'fbp_error_percent',
					'fbp_attempts_per_game',
					'perfect_fbp/game',
					'fbp_errors/game',

					'block_attempts',
					'blocks',
					'block_errors',
					'net_blocks',
					'percent_of_team_block_attempts',
					'block_percent',
					'block_error_percent',
					'block_attempts_per_game',
					'blocks_per_game',
					'block_errors_per_game',

					'points_earned',
					'points_lost',
					'points_net',
					'percent_of_team_points_earned',
					'percent_of_team_points_lost',
					'percent_of_team_points_net',
					'earned_cnt_per_game',
					'lost_cnt_per_game',
					'eef_cnt_per_game'

				];
				var rotationStats = Stats.calc(rotationStatsPrep.byRow, stat_columns, {
					blockAssistsHalfValue: true,
					who: "us",
					inRally: inRally
				});


				// add stats that can't be calculated by Stats module -- TODO: extract to function
				var us_score = 0;
				var them_score = 0;
				if (this.current.game) {
					us_score = this.current.game.score.us;
					them_score = this.current.game.score.them;
				} else if (this.games.length) {
					_.forEach(this.games, function(game) {
						us_score += game.score.us;
						them_score += game.score.them;
					});
				}

				// Team Total
				var summaryData = Stats.calc([rotationStatsPrep.agg], stat_columns, {
					blockAssistsHalfValue: true,
					who: "us",
					agg: true,
					inRally: inRally
				});
				summaryData[0].player_name = "TOTAL";

				for (var i=0; i < rotationStats.length; i++){
					rotationStats[i]['percent_of_team_points_earned'] = ((rotationStats[i]['points_earned'] / summaryData[0]['points_earned']) * 100).toFixed(1) + '%';
					if (rotationStats[i]['percent_of_team_points_earned'] === 'Infinity%'){
						rotationStats[i]['percent_of_team_points_earned'] = '--';
					}
					rotationStats[i]['percent_of_team_points_lost'] = ((rotationStats[i]['points_lost'] / summaryData[0]['points_lost']) * 100).toFixed(1) + '%';
					if (rotationStats[i]['percent_of_team_points_lost'] === 'Infinity%'){
						rotationStats[i]['percent_of_team_points_lost'] = '--';
					}
					rotationStats[i]['percent_of_team_points_net'] = (((rotationStats[i]['points_earned'] / summaryData[0]['points_earned']) - (rotationStats[i]['points_lost'] / summaryData[0]['points_lost'])) * 100).toFixed(1) + '%';
					if (rotationStats[i]['percent_of_team_points_net'] === 'Infinity%'){
						rotationStats[i]['percent_of_team_points_net'] = '--';
					}
					rotationStats[i]['percent_of_team_serve_attempts'] = ((rotationStats[i]['serve_attempts'] / summaryData[0]['serve_attempts']) * 100).toFixed(1) + '%';
					rotationStats[i]['percent_of_team_rcv_attempts'] = ((rotationStats[i]['pass_attempts'] / summaryData[0]['pass_attempts']) * 100).toFixed(1) + '%';
					rotationStats[i]['percent_of_team_kill_attempts'] = ((rotationStats[i]['combined_kill_attempts'] / summaryData[0]['combined_kill_attempts']) * 100).toFixed(1) + '%';
					rotationStats[i]['percent_of_team_dig_attempts'] = ((rotationStats[i]['dig_attempts'] / summaryData[0]['dig_attempts']) * 100).toFixed(1) + '%';
					rotationStats[i]['percent_of_team_block_attempts'] = ((rotationStats[i]['block_attempts'] / summaryData[0]['block_attempts']) * 100).toFixed(1) + '%';
					rotationStats[i]['percent_of_team_fbp_attempts'] = ((rotationStats[i]['fbp_attempts'] / summaryData[0]['fbp_attempts']) * 100).toFixed(1) + '%';
					if (this.current.game){
						// games played percent N/A for individual game view (always 100%)
						rotationStats[i]['games_played_percent'] = '--';
					} else {
						rotationStats[i]['games_played_percent'] = ((rotationStats[i]['games_played'] / this.games.length) * 100).toFixed(1) + '%';
					}
					rotationStats[i]['points_played_percent'] = ((rotationStats[i]['points_played'] / (us_score + them_score)) * 100).toFixed(1) + '%';
				}
				summaryData[0]['percent_of_team_points_earned'] = '100%';
				summaryData[0]['percent_of_team_points_lost'] = '100%';
				summaryData[0]['percent_of_team_points_net'] = '0%';
				summaryData[0]['percent_of_team_serve_attempts'] = '100%';
				summaryData[0]['percent_of_team_rcv_attempts'] = '100%';
				summaryData[0]['percent_of_team_kill_attempts'] = '100%';
				summaryData[0]['percent_of_team_dig_attempts'] = '100%';
				summaryData[0]['percent_of_team_block_attempts'] = '100%';
				summaryData[0]['percent_of_team_fbp_attempts'] = '100%';
				summaryData[0]['games_played_percent'] = '--';
				summaryData[0]['points_played_percent'] = '--';

				// add row for Team Total
				rotationStats.push(summaryData[0]);

				return rotationStats;
			}
		},
	},
	computed: {
		arrowdir() {
			return this.current.carouselSelectorOpen ? "arrow-down" : "arrow-up";
		},
		closed() {
			return this.current.carouselSelectorOpen ? "" : "closed";
		},
		hasGames() {
			return this.games.length > 0;
		},
		gameNums() {
			let x = _.map(this.games, 'num');
			for (let i=0; i < x.length; i++){
				x[i] = parseInt(x[i]);
			}
			return x;
		},
		matchCount() {
			var count = this.current.selected_matches.length;
			if (this.current.last) {
				count = this.lastMatches.length;
			} else if (this.current.season) {
				count = this.matches.length;
			}
			return count;
		},
		gameCount() {
			return this.games.length;
		},
		currentPlayerNum() {
			return this.current.player ? this.current.player.num : null;
		},
		showGameView() {
			return !this.current.last && !this.current.season && (this.current.selected_matches.length <= 1);
		},
		showSummary(){
			if (!this.current.carouselSelectorOpen){
				return 'show-summary';
			}
			return '';
		},
		scoreType(){
			if (this.current.showGamesWon){
				return 'game';
			} else {
				return 'match';
			}
		},
		scoreGames() {
			var score = {
				us: '',
				them: ''
			};

			if (this.current.game) {
				score = this.current.game.score;
			} else if (this.games.length) {
				score = {
					us: 0,
					them: 0
				};
				_.forEach(this.games, function(game) {
					if (gameComplete(game)) {
						if (game.score.us > game.score.them) {
							score.us++;
						} else {
							score.them++;
						}
					}
				});
			}
			return score;
		},
		scoreMatches() {
			var score = {
				us: '',
				them: ''
			};
			var relevantMatches = [];
			var matchGames = {};

			if (this.current.game) {
				score = this.current.game.score;
			} else if (this.current.match && this.games.length) {
				score = {
					us: 0,
					them: 0
				};
				var gamesScore = {
					us: 0,
					them: 0
				};
				_.forEach(this.games, function(game) {
					if (gameComplete(game)) {
						if (game.score.us > game.score.them) {
							gamesScore.us++;
						} else {
							gamesScore.them++;
						}
					}
				});
				if (gamesScore.us > gamesScore.them) {
					score.us++;
				} else {
					score.them++;
				}
			} else if (this.games.length && (this.current.last || this.current.season || this.current.selected_matches.length)) {
				// if multiple matches are selected, then use match score
				score = {
					us: 0,
					them: 0
				};

				// get relevant matches
				if (this.current.last){
					relevantMatches = this.lastMatches;
				} else if (this.current.season){
					relevantMatches = this.matches;
				} else if (this.current.selected_matches.length){
					relevantMatches = this.current.selected_matches;
				}

				// sort games by match and add up scores
				for (var i=0; i < relevantMatches.length; i++){
					matchGames[relevantMatches[i].id] = { games:[], score_us:0, score_them:0 };
					for (var j=0; j < this.games.length; j++){
						if (this.games[j].match_id == relevantMatches[i].id && gameComplete(this.games[j])){
							matchGames[relevantMatches[i].id].games.push(this.games[j]);
							if (this.games[j].score.us > this.games[j].score.them){
								matchGames[relevantMatches[i].id].score_us++;
							} else {
								matchGames[relevantMatches[i].id].score_them++;
							}
						}
					}
				}

				// get total won matches
				for (var mg in matchGames){
					// matches with fewer than 2 points on one side are not considered complete
					if (matchGames[mg].score_us > matchGames[mg].score_them){
						score.us++;
					} else {
						score.them++;
					}
				}

			}
			return score;
		},
		timeouts() {
			if (this.current.game) {
				return this.current.game.timeout_state;
			} else {
				return null;
			}
		},
		teamName() {
			return this.current.team ? this.current.team.name : "Unknown Team";
		},
		parsedMatchGameLogs() {
			var data = [];
			_.forEach(this.games, function(game) {
				data = data.concat(parseGameLog(game));
			});
			return data.length ? data : null;
		},
		parsedGameLog() {
			return this.current.game ? parseGameLog(this.current.game) : null;
		},
		summaryStats() {
			if (this.current.match || this.games) {
				var games = this.current.game ? [this.current.game.id] : _.map(this.games, 'id');
				var logs = this.current.game ? this.parsedGameLog : this.parsedMatchGameLogs;
				var processed_game_stats = StatPrep.processStatsIteration(logs, {
					type: 'game',
					list: games
				}, null, false);
				var inRally = {
					pass: true,
					hit: true,
					dig: true
				};
				var stat_columns = [
					'points_for',
					'points_against',
					'game_id',
					'combined_kills',
					'combined_kill_errors_minus_block_details',
					'aces',
					'blocks',
					'earned_blck',
					'serve_errors',
					'pass_rating',
					'pt_score_percent',
					'side_out_percent',
					'us_adjustment',
					'them_adjustment'
				];
				var stats_us = Stats.calc([processed_game_stats.agg], stat_columns, {
					blockAssistsHalfValue: true,
					who: "us",
					agg: true,
					inRally: inRally
				});
				var stats_them = Stats.calc([processed_game_stats.agg], stat_columns, {
					blockAssistsHalfValue: true,
					who: "them",
					agg: true,
					inRally: inRally
				});
				return {
					us: stats_us,
					them: stats_them
				};
			}
		},
		playerStats() {
			if (this.current.match || this.games.length) {
				var games = this.current.game ? [this.current.game.id] : _.map(this.games, 'id');
				var logs = this.current.game ? this.parsedGameLog : this.parsedMatchGameLogs;
				var players = _.map(this.current.team.players, 'num');
				var player_stats = StatPrep.processStatsIteration(logs, {
					type: 'player',
					list: players,
					listNames: this.playerNames()
				}, {
					type: 'game',
					list: games
				});
				return player_stats;
			}
		},
		rotationStats() {
			return this.getRotationStats();
		},
		theirSrvRotationStats() {
			return this.getRotationStats(true);
		}
	}
});

Vue.component('match-select', {
	template: '<div class="match-list-wrap">' +
		'<header class="pageHeader">' +
		'<div v-if="team" class="match-list">' +
		'<a class="pageHeader-back" href="#" v-on:click="selectTeam"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/186163/arrow.svg" /></a>' +
		'<h1>{{ teamName }}</h1>' +
		'</header>' +
		'<div class="initializing" id="loading-team"><i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;L O A D I N G &nbsp;&nbsp; D A T A . . . </div>' +
		'<div v-if="hasMatches">' +
		'<match-filter-select :current="current"></match-filter-select>' +
		'<match-view-confirm :current="current" v-on:click.native="goToSelectedMatches"></match-view-confirm>' +
		//'<pick-season :score="score"></pick-season>' +
		//'<pick-last-day :matches="matches" :last-matches="lastMatches"></pick-last-day>' +
		'<div class="match-list-scroll">' +
		'<pick-match v-for="match in matches" :match="match" :games="games" :current="current"></pick-match>' +
		//'<div class="pageFooterSpacer"></div>' +
		'</div>' +
		'</div>' +
		'<div v-else class="no-visible-matches" style="display:none;">No matches are available</div>' +
		'</div></div>',
	props: ['team', 'matches', 'games', 'lastMatches', 'lastMatches2', 'lastMatches3', 'lastMatches4', 'current', 'matchFilter'],
	computed: {
		hasMatches() {
			return this.matches.length > 0;
		},
		teamName() {
			return this.team ? this.team.name : "Unknown Team";
		},
		score() {
			var seasonScore = {
				us: '',
				them: ''
			};

			if (this.hasMatches) {
				seasonScore = {
					us: 0,
					them: 0
				};
				_.forEach(this.matches, function(match) {
					var matchScore = {
						us: 0,
						them: 0
					};
					_.forEach(match.games, function(game) {
						if (gameComplete(game)) {
							if (game.score.us > game.score.them) {
								matchScore.us++;
							} else {
								matchScore.them++;
							}
						}
					});
					if (matchScore.us > matchScore.them) {
						seasonScore.us++;
					} else if (matchScore.us < matchScore.them) {
						seasonScore.them++;
					}
				});
			}

			return seasonScore;
		}
	},
	methods: {
		selectTeam() {
			eventHub.$emit('clear-team');
		},
		goToSelectedMatches() {
			if (!this.current.selected_matches.length){
				return false;
			} else if (this.current.selected_matches.length === 1){
				eventHub.$emit('set-match', this.current.selected_matches[0]);
			} else {
				eventHub.$emit('set-matches', this.current.selected_matches);
			}
		},
		autoSelectMatches(option){
			if (option === undefined){
				option = this.matchFilter;
			}
			if (option === 0){
				eventHub.$emit('set-selected-matches', this.matches);
			} else if (option === 1 && this.matches.length){
				eventHub.$emit('set-selected-matches', [_.cloneDeep(this.matches[0])]);
			} else if (option === 2){
				eventHub.$emit('set-selected-matches', this.lastMatches);
			} else if (option === 3){
				eventHub.$emit('set-selected-matches', this.lastMatches2);
			} else if (option === 4){
				eventHub.$emit('set-selected-matches', this.lastMatches3);
			} else if (option === 5){
				eventHub.$emit('set-selected-matches', this.lastMatches4);
			}
		}
	},
	watch: {
		matches(){
			return this.autoSelectMatches();
		},
		matchFilter(newVal){
			return this.autoSelectMatches(newVal);
		}
	}
});




Vue.component('match-filter-select', {
	template: `
	<div class="pageNav owl-carousel" id="match-filter-select">
		<a class="pageNav-item" :class="current.matchFilter === 0 ? 'is-selected' : ''" v-on:click="selectFilter(0, $event)"><button class="game-btn btn"><span>All Matches</span></button></a>
		<a class="pageNav-item" :class="current.matchFilter === 1 ? 'is-selected' : ''" v-on:click="selectFilter(1, $event)"><button class="game-btn btn"><span>Last Match</span></button></a>
		<a class="pageNav-item" :class="current.matchFilter === 2 ? 'is-selected' : ''" v-on:click="selectFilter(2, $event)"><button class="game-btn btn"><span>Last Day</span></button></a>
		<a class="pageNav-item" :class="current.matchFilter === 3 ? 'is-selected' : ''" v-on:click="selectFilter(3, $event)"><button class="game-btn btn"><span>Last 2 Days</span></button></a>
		<a class="pageNav-item" :class="current.matchFilter === 4 ? 'is-selected' : ''" v-on:click="selectFilter(4, $event)"><button class="game-btn btn"><span>Last 3 Days</span></button></a>
		<a class="pageNav-item" :class="current.matchFilter === 5 ? 'is-selected' : ''" v-on:click="selectFilter(5, $event)"><button class="game-btn btn"><span>Last 4 Days</span></button></a>
	</div>
	`,
	props: ['current'],
	methods: {
		selectFilter(option_num, event) {
			if ($(event.currentTarget).attr('disabled')){
				return;
			}
			this.owlCarousel.trigger('to.owl.carousel', $(event.currentTarget).parent().index());
			eventHub.$emit('set-match-filter', option_num);
			$('#match-filter-select .pageNav-item').removeClass('is-selected');
			$('#match-filter-select .pageNav-item').addClass(function(index) {
				return index === option_num ? 'is-selected' : null;
			});
		}
	},
	mounted(){
		// activate OwlCarousel on select buttons
		this.$nextTick(() => {
			this.owlCarousel = $('#match-filter-select.owl-carousel').owlCarousel({
				loop:false,
				items:SELECTOR_ITEMS,
				nav:true,
				center:true,
				dots:false,
				startPosition:this.current.matchFilter
			}).on('changed.owl.carousel', function(e){

				$(e.target).find(".owl-item").eq(e.item.index).find('.pageNav-item').get(0).click();
				$('button').blur();
			});
		});
	}
});

Vue.component('pick-match', {
	template: `<a href="#" :class="'linkRow ' + selected" v-on:click="selectMatch">` +
		'<div class="linkRow-left">' +
		'<strong>' +
		'<span v-if="matchPending" style="color:green;">UPCOMING<br /></span>' +
		'<span v-if="matchLive" style="color:red;">LIVE!<br /></span>' +
		'vs {{ match.opponent }}' +
		'</strong><br />' +
		'<small>{{ matchDate }}</small><br />' +
		'<small>@ {{ match.venue }} {{ matchCourt }}</small>' +
		'</div>' +
		'<div class="linkRow-right">' +
		// '<strong class="txt-huge">{{ matchScore.us }} - {{ matchScore.them }}</strong>' +
		// '<strong class="winLossBadge">{{ matchScore.record }}</strong>' +
		'</div>' +
		'</a>',
	props: ['games', 'match', 'current'],
	computed: {
		matchDate() {
			if (this.match.start_dt) {
				return displayDateTime(this.match.start_dt);
			} else {
				return displayDate(this.match.created);
			}
		},
		matchCourt() {
			if (this.match.court) {
				return "Ct " + this.match.court;
			} else {
				return "";
			}
		},
		matchLive() {
			if (this.match.finished){
				return false;
			}
			return (this.match.games && this.match.games.length > 0 && !gameComplete(_.last(_.sortBy(this.match.games, ['num']))));
		},
		matchPending() {
			return (this.match.games && this.match.games.length === 0);
		},
		matchScore() {
			var score = {
				us: '',
				them: '',
				record: ''
			};

			score.us = 0;
			score.them = 0;
			_.forEach(this.match.games, function(game) {
				if (gameComplete(game)) {
					if (game.score.us > game.score.them) {
						score.us++;
					} else {
						score.them++;
					}
				}
			});

			if (!this.matchLive && !this.matchPending) {
				if (score.us > score.them) {
					score.record = 'W';
				}
				if (score.us < score.them) {
					score.record = 'L';
				}
			}

			return score;
		},
		selected() {
			for (var i=0; i < this.current.selected_matches.length; i++){
				if (this.match.id === this.current.selected_matches[i].id){
					return "selected";
				}
			}
			return "";
		}
	},
	methods: {
		selectMatch() {
			eventHub.$emit('set-match-filter', null);
			eventHub.$emit('set-selected-match', this.match);
		}
	}
});


Vue.component('team-select', {
	template: '<div class="team-list">' +
		'<header class="pageHeader">' +
		'<a class="pageHeader-back" href="#" v-on:click="selectDevice"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/186163/arrow.svg" /></a>' +
		'<h1>Select a Team</h1>' +
		'</header>' +
		'<div v-if="hasTeams"><team v-for="team in teams" :name="team.name" :id="team.id"></team></div>' +
		'<div v-else class="initializing" id="initializing">' +
		'<i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;I N I T I A L I Z I N G . . . ' +
		'</div>' +
		'</div>',
	props: ['teams'],
	computed: {
		hasTeams() {
			return this.teams.length > 0;
		}
	},
	methods: {
		selectDevice() {
			eventHub.$emit('clear-device');
		}
	}
});

Vue.component('team', {
	template: '<a href="#" class="linkRow" v-on:click="selectTeam">' +
		'<div class="linkRow-left">' +
		'<strong>{{ name }}</strong>' +
		'</div>' +
		'</a>',
	props: ['name', 'id'],
	methods: {
		selectTeam() {
			eventHub.$emit('set-team', this.id, null, null);
		}
	}
});

Vue.component('device-select', {
	template: '<div class="device-list">' +
		'<header class="pageHeader"><h1>Select a Device</h1></header>' +
		'<div v-if="hasDevices"><device v-for="device in devices" :name="device.name" :uid="device.uid" :teams="device.teams"></device></div>' +
		'<div v-else class="initializing" id="initializing"><i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;I N I T I A L I Z I N G . . . </div>' +
		'<div class="no-visible-following" style="display:none;">No devices found. Make sure you are connected to the Internet.<br /><br />You must have a current subscription to Web Reports Pro to access team data.</div>' +
		'</div>',
	props: ['teams', 'devices'],
	computed: {
		hasDevices() {
			return this.devices.length > 0;
		}
	}
});

Vue.component('device', {
	template: `
		<a href="#" class="linkRow" v-on:click="selectDevice">
			<div class="linkRow-left">
				<span v-if="name">
					<strong>{{ name }}</strong>
					<br />
					{{ formattedTeams }}
				</span>
				<strong v-else>{{ uid }}</strong>
			</div>
		</a>
	`,
	props: ['name', 'uid', 'teams'],
	methods: {
		selectDevice() {
			eventHub.$emit('set-device', this.uid);
		}
	},
	computed: {
		formattedTeams() {
			return this.teams.replace(/,/g, ', ');
		}
	}
});

Vue.component('login', {
	template: '<div>' +
		'<login-nav :login="showLogin"></login-nav>' +
		'<login-tabs :login="showLogin"></login-tabs>' +
		'</div>',
	props: ['has_account'],
	computed: {
		showLogin() {
			var email = Utils.getParameterByName('email');
			if (this.has_account || email === null) {
				return true;
			} else {
				return false;
			}
		}
	}
});

Vue.component('login-nav', {
	template: `
		<ul id="myTabs" class="tabNav" role="tablist">
			<li role="presentation" class="nav-item">
				<a href="#home" aria-controls="home" class="nav-link" :class="{ active: login } "role="tab" data-bs-toggle="tab" data-bs-target="#home">Sign In</a>
			</li>
			<li role="presentation" class="nav-item">
				<a href="#profile" aria-controls="profile" class="nav-link" :class="{ active: !login }" role="tab" data-bs-toggle="tab" data-bs-target="#profile">Sign Up</a>
			</li>
		</ul>
	`,
	props: ['login']
});

Vue.component('login-tabs', {
	template: '<div class="tab-content">' +
		'<login-tab-home :login="login"></login-tab-home>' +
		'<login-tab-profile :login="login"></login-tab-profile>' +
		'</div>',
	props: ['login']
});

Vue.component('login-tab-home', {
	template: '<div role="tabpanel" class="tab-pane" id="home" :class="{ active: login }">' +
		'<div class="block">' +
		'<form class="loginForm">' +
		'<input type="email" id="login-email" placeholder="Email" :value="inviteEmail()">' +
		'<input type="password" id="login-password" placeholder="Password">' +
		'<div class="loginCtrls">' +
		'<div class="checkbox-inline"><label><input type="checkbox" id="remember"> Remember me</label></div>' +
		'<a href="#" id="login-reset">Reset your password</a>' +
		'</div>' +
		'<button type="submit" v-on:click="doLogin">Sign In</button>' +
		'</form>' +
		'<form class="resetLoginForm" style="display:none;">' +
		'<input type="email" id="reset-email" placeholder="Email" :value="inviteEmail()">' +
		'<button type="submit" v-on:click="doReset">Reset Password</button>' +
		'</form>' +
		'</div>' +
		'</div>',
	props: ['login'],
	mounted() {
		if (Utils.getCookie('RM') == '1') {
			$("#remember").attr('checked', 'checked');
		}

		if ($("#remember").is(':checked')) {
			$("#login_email").val(Utils.getCookie('EM'));
			$("#login_password").val(Utils.getCookie('PW'));
		}

		$("#remember").click(function() {
			if (this.checked) {
				Utils.setCookie('RM', '1');
			} else {
				Utils.setCookie('RM', '0');
			}
		});

		$("#login-reset").click(function() {
			$(".resetLoginForm").show();
			$('html, body').animate({
				scrollTop: $(document).height()
			}, 'slow');
			return false;
		});
	},
	methods: {
		inviteEmail() {
			return Utils.getParameterByName('email');
		},
		doLogin(e) {
			var loginEmail = Utils.strip($("#login-email").val());
			var loginPassword = Utils.strip($("#login-password").val());
			if (Utils.getCookie('RM') == '1') {
				Utils.setCookie('EM', loginEmail);
				Utils.setCookie('PW', loginPassword);
			}
			if (loginEmail === "") {
				alert("Please enter your email.");
			} else if (!Utils.checkEmail(loginEmail)) {
				alert("The email address you entered is invalid.");
			} else if (loginPassword === "") {
				alert("Please enter your password.");
			} else {
				$.ajax({
					url: "/q/login",
					type: "post",
					dataType: "json",
					data: {
						em: loginEmail,
						pw: loginPassword
					},
					success(pair) {
						if (pair[0] === 0) {
							eventHub.$emit('set-user', pair[1], 0);
						} else {
							alert(pair[1]);
						}
					}
				});
			}
			e.preventDefault();
		},
		doReset(e) {
			var resetEmail = Utils.strip($("#reset-email").val());
			if (resetEmail === "") {
				alert("You need to enter an email address.");
			} else if (!Utils.checkEmail(resetEmail)) {
				alert("The email address you entered is invalid.");
			} else {
				$.ajax({
					url: "/q/forgot",
					type: "post",
					data: {
						em: Utils.strip($("#reset-email").val())
					},
					dataType: "json",
					success() {
						alert("Email sent with instructions to reset your password");
					}
				});
				e.preventDefault();
			}
		}
	}
});

Vue.component('login-tab-profile', {
	template: '<div role="tabpanel" class="tab-pane" id="profile" :class="{ active: !login }">' +
		'<div class="block">' +
		'<form class="loginForm">' +
		'<p>Not a member of SoloStats?  Sign up is free:</p>' +
		'<input type="text" id="reg-first-name" placeholder="First Name" />' +
		'<input type="text" id="reg-last-name" placeholder="Last Name" />' +
		'<input type="email" id="reg-email" placeholder="Email"  :value="inviteEmail()" />' +
		'<input type="email" id="reg-email-confirm" placeholder="Confirm Email"  :value="inviteEmail()" />' +
		'<input type="password" id="reg-password" placeholder="Password" />' +
		'<input type="password" id="reg-password-confirm" placeholder="Confirm Password" />' +
		'<button type="submit" v-on:click="doRegistration">Register</button>' +
		'</form>' +
		'</div>' +
		'</div>',
	props: ['login'],
	methods: {
		inviteEmail() {
			return Utils.getParameterByName('email');
		},
		doRegistration(e) {
			var regFirstName = Utils.strip($("#reg-first-name").val());
			var regLastName = Utils.strip($("#reg-last-name").val());
			var regEmail = Utils.strip($("#reg-email").val());
			var regPassword = Utils.strip($("#reg-password").val());
			var regConfirmEmail = Utils.strip($("#reg-email-confirm").val());
			var regConfirmPassword = Utils.strip($("#reg-password-confirm").val());
			if (regFirstName === "") {
				alert("Please enter your first name.");
			} else if (regLastName === "") {
				alert("Please enter your last name.");
			} else if (regEmail === "") {
				alert("Please enter your email.");
			} else if (regConfirmEmail === "") {
				alert("Please confirm your email.");
			} else if (!Utils.checkEmail(regEmail)) {
				alert("The email address you entered is invalid.");
			} else if (regEmail !== regConfirmEmail) {
				alert("Your email and confirm email must be the same.");
			} else if (regPassword === "") {
				alert("Please enter your password.");
			} else if (regConfirmPassword === "") {
				alert("Please confirm your password.");
			} else if (regPassword !== regConfirmPassword) {
				alert("Your password and confirm password must be the same.");
			} else {
				$.ajax({
					url: "/q/register",
					type: "post",
					dataType: "json",
					data: {
						fn: regFirstName,
						ln: regLastName,
						em: regEmail,
						pw: regPassword,
						isFan: false
					},
					success(pair) {
						if (pair[0] === 0) {
							eventHub.$emit('set-user', pair[1], 5000);
						} else {
							alert(pair[1]);
						}
					}
				});
			}
			e.preventDefault();
		}
	}
});

Vue.component('invite-reg-login', {
	template: '<div>' +
		'<login :has_account="has_account"></login>' +
		'</div>',
	props: ['has_account'],
});

Vue.component('navbar', {
	template: `
		<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top py-1">
			<div class="container-fluid">
				<a class="navbar-brand" href="#">SoloStats <span>COACH</span></a>
				<button v-if="isSignedIn"
					type="button" class="navbar-toggler"
					data-bs-toggle="collapse" data-bs-target="#navbar-collapse-1"
					aria-expanded="false" aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbar-collapse-1">
					<ul class="navbar-nav me-auto" v-if="isSignedIn">
						<li class="nav-item">
							<a class="nav-link active" href="#" v-on:click="selectDevices">Devices</a>
						</li>
						<li class="nav-item" v-show="doNotPoll">
							<a class="nav-link" href="#" v-on:click="resumePolling">Resume Polling</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="#" v-on:click="selectRefresh">Refresh</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="https://www.solostatslive.com/training-solostats-coach.html" target="_blank">Help</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/logout?redirect=/coach" onClick="return(confirm('Are you sure you want to sign out?'));">Sign Out</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	`,
	props: ['uid', 'doNotPoll'],
	computed: {
		isSignedIn() {
			if (this.uid !== '') {
				return true;
			} else {
				return false;
			}
		}
	},
	methods: {
		selectDevices() {
			eventHub.$emit('clear-device');
		},
		selectRefresh() {
			eventHub.$emit('refresh');
		},
		resumePolling() {
			eventHub.$emit('resume-polling');
		}
	}
});

Vue.component('modal-dialog', {
	template: '<div id="modal-dialog" v-if="dialogMsg" class="modal-dialog-outer">' +
		'<div class="modal-dialog-inner">' +
		'<div class="modal-dialog-header">{{ dialogHeader }}</div>' +
		'<div class="modal-dialog-body">{{ dialogBody }}</div>' +
		'<div class="modal-dialog-buttons">' +
		'<div class="modal-dialog-button" v-on:click="doLearnMore" v-if="dialogMsg === \'no-subscription\'">Learn More</div>' +
		'<div class="modal-dialog-button close-button" v-on:click="doClose">Close</div>' +
		'</div>' +
		'</div>' +
		'</div>',
	props: ['dialogMsg'],
	computed: {
		dialogHeader() {
			if (this.dialogMsg === 'no-matches') {
				return 'Take More Stats';
			} else if (this.dialogMsg === 'no-subscription'){
				return 'Get Reports!';
			}
			return 'Hi';
		},
		dialogBody() {
			if (this.dialogMsg === 'no-matches') {
				return 'You need to take more stats in order to see your reports.';
			} else if (this.dialogMsg === 'no-subscription'){
				return 'Subscribe to Reports to analyze stats for all your matches.';
			}
			return 'Don\'t forget to stay hydrated!';
		}
	},
	methods: {
		doClose() {
			eventHub.$emit('set-dialog-msg', null);
			if (this.$root.page === 'device-select' && this.$root.devices.length === 0){
				$(".no-visible-following").show();
			}
		},
		doLearnMore() {
			eventHub.$emit('set-dialog-msg', null);
			window.open('https://www.solostatslive.com/solostats-webreports.html', '_blank');
		}
	}
});

Vue.filter('displayDate', function(value) {
	var dateTime = new Date(value);
	var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	return month[dateTime.getMonth()] + ' ' + dateTime.getDate() + ', ' + dateTime.getFullYear();
});

var API_URL = '';
var eventHub = new Vue();

$(document).ready(function() {

	// check for flex support
	if ($('#coach_app').get(0).style.flex === undefined){
		$('#coach_app').hide();
		alert('Browser not supported. Please update your browser or your device\'s OS to use this app.');
		return;
	}

	Vue.config.devtools = false;
	var vm = new Vue({
		el: '#coach_app',
		data: {
			user_uid: '',
			has_account: false,
			devices: [],
			hidden_devices: [],
			teams: [],
			matches: [],
			games: [],
			lastMatches: [],
			lastMatches2: [],
			lastMatches3: [],
			lastMatches4: [],
			dialogMsg: null,
			page: 'device-select',
			current: {
				device: null,
				team: null,
				match: null,
				game: null,
				selected_matches: [],
				matchFilter: 1,
				stat: 2,
				rotation_stat: 2,
				stat_sort: 'player_name',
				stat_order: '1',
				report_style: 1,
				report_type: 1,
				report_detail: 1,
				error_tree_type: 0,
				allPlayers: false,
				last: false,
				season: false,
				carouselSelectorOpen: true,
				showGamesWon: false
			},
			hideNavbar: !!Utils.getParameterByName('hide_navbar'),
			doNotPoll: false,
			pathArray: window.location.pathname.split('/'),
			preload: {
				device: Utils.getParameterByName('d') || null,
				team: Utils.getParameterByName('t') || null,
			},


		},
		created() {
			eventHub.$on('set-user', this.setUser);
			eventHub.$on('refresh', this.refresh);
			eventHub.$on('clear-device', this.clearDevice);
			eventHub.$on('clear-team', this.clearTeam);
			eventHub.$on('clear-match', this.clearMatch);
			eventHub.$on('set-device', this.setDevice);
			eventHub.$on('set-team', this.setTeam);
			eventHub.$on('set-match', this.setMatch);
			eventHub.$on('set-matches', this.setMatches);
			eventHub.$on('set-game', this.setGame);
			eventHub.$on('set-last-day', this.setLastDay);
			eventHub.$on('set-season', this.setSeason);
			eventHub.$on('set-selected-matches', this.setSelectedMatches);
			eventHub.$on('set-selected-match', this.setSelectedMatch);
			eventHub.$on('set-match-filter', this.setMatchFilter);
			eventHub.$on('toggle-carousel-open', this.toggleCarouselOpen);
			eventHub.$on('toggle-score-view', this.toggleScoreView);
			eventHub.$on('set-dialog-msg', this.setDialogMsg);
			eventHub.$on('resume-polling', this.resumePolling);

			var that = this;
			return this.loadInitialData()
				.then(function() {
					if (that.user_uid === '') {
						that.page = 'invite-reg-login';
						Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'invite-reg-login');
					} else {
						that.loadDevicesData();
					}
				});
		},
		mounted() {
			this.pollMatches();
			this.pollMatchGames();
		},
		methods: {
			setUser(user_uid, wait) {
				this.user_uid = user_uid;
				this.page = 'device-select';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'home');
				var that = this;
				setTimeout(function() {
					that.loadDevicesData();
				}, wait);
			},
			refresh() {
				this.page = 'device-select';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'refresh');
				this.current.match = null;
				this.current.team = null;
				this.current.season = null;
				this.current.selected_matches = [];
				this.devices = [];
				this.teams = [];
				this.matches = [];
				this.games = [];
				this.dialogMsg = null;
				$("#initializing").show();
				$(".no-visible-following").hide();
				this.loadDevicesData();
			},
			clearDevice() {
				this.page = 'device-select';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'home');
				this.current.device = null;
				this.current.team = null;
				this.current.match = null;
				this.current.game = null;
				this.current.stat = 1;
				this.current.rotation_stat = 1;
				this.current.last = false;
				this.current.season = false;
				this.current.selected_matches = [];
			},
			clearTeam() {
				this.page = 'team-select';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'team-select');
				this.current.team = null;
				this.current.match = null;
				this.current.game = null;
				this.current.stat = 1;
				this.current.rotation_stat = 1;
				this.current.last = false;
				this.current.season = false;
				this.current.selected_matches = [];
			},
			clearMatch() {
				this.page = 'match-select';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'match-select');
				this.current.match = null;
				this.current.game = null;
				this.current.last = false;
				this.current.season = false;
				this.current.selected_matches = [];
				this.matches = _.cloneDeep(this.matches);
			},
			setDevice(uid) {
				$('.no-visible-matches').hide();
				this.page = 'team-select';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'team-select');
				this.current.device = uid;
				this.current.allPlayers = true;
				this.games = [];
				this.matches = [];
				$('#loading-team').show();
				this.loadTeamData(uid);
			},
			setTeam(team_id, player_id, match_id) {
				$('.no-visible-matches').hide();
				this.page = 'match-select';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'match-select');
				this.current.team = _.find(this.teams, ['id', team_id]);
				this.games = [];
				this.matches = [];
				this.current.selected_matches = [];
				$('#loading-team').show();
				this.loadMatchData(this.current.team, false, match_id);
			},
			setMatch(match) {
				this.current.last = false;
				this.current.season = false;
				this.current.stat = 2;
				this.current.rotation_stat = 2;
				this.current.report_style = 1;
				this.current.report_type = 0;
				this.current.report_detail = 1;
				this.current.carouselSelectorOpen = true;
				this.current.selected_matches = [match];
				this.current.match = _.find(this.matches, ['id', match.id]);
				this.current.game = null;
				this.current.showGamesWon = true;
				this.page = 'match-view';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'match-view');
				this.games = [];
				$('#loading-match').show();
				this.loadMatchGamesData(this.current.team, this.current.match, false);
			},
			setMatches(matches) {
				this.current.last = false;
				this.current.season = false;
				this.current.stat = 2;
				this.current.rotation_stat = 2;
				this.current.report_style = 1;
				this.current.report_type = 0;
				this.current.report_detail = 1;
				this.current.carouselSelectorOpen = true;
				this.current.selected_matches = matches;
				this.current.game = null;
				this.current.showGamesWon = false;
				this.page = 'match-view';
				Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'matches-view');
				this.games = [];
				$('#loading-match').show();
				this.loadMatchesGamesData(this.current.team, this.current.selected_matches);
			},
			setGame(game_num) {
				this.current.last = false;
				this.current.season = false;
				this.page = 'match-view';
				if (game_num > 0) {
					this.current.game = _.find(this.games, {
						'match_id': this.current.match.id,
						'num': game_num
					});

					// In older data our match_id was a string. So, check to see if
					// we have a match there.
					if (!this.current.game) {
						this.current.game = _.find(this.games, {
							'match_id': this.current.match.id.toString(),
							'num': game_num
						});
					}

					if (!this.current.game) {
						game_num = 0;
					}
				} else {
					this.current.game = null;
				}
				$('#match-view-game-select .pageNav-item').removeClass('is-selected');
				$('#match-view-game-select .pageNav-item').addClass(function(index) {
					return index === game_num ? 'is-selected' : null;
				});
			},
			setLastDay() {
				this.current.last = true;
				this.current.season = false;
				this.current.stat = 2;
				this.current.rotation_stat = 2;
				this.current.report_style = 1;
				this.current.report_type = 0;
				this.current.report_detail = 1;
				this.current.selected_matches = [];
				this.page = 'match-view';
				this.games = [];
				$('#loading-match').show();
				this.loadMatchesGamesData(this.current.team, this.lastMatches);
			},
			setSeason() {
				this.current.last = false;
				this.current.season = true;
				this.current.stat = 2;
				this.current.rotation_stat = 2;
				this.current.report_style = 1;
				this.current.report_type = 0;
				this.current.report_detail = 1;
				this.current.selected_matches = [];
				this.page = 'match-view';
				this.games = [];
				$('#loading-match').show();
				this.loadAllMatchesGamesData(this.current.team);
			},
			setSelectedMatches(matches){
				this.current.selected_matches = _.cloneDeep(matches);
				return this.current.selected_matches;
			},
			setSelectedMatch(match){
				this.current.match = null;
				for (var i=0; i < this.current.selected_matches.length; i++){
					if (match.id === this.current.selected_matches[i].id){
						return this.current.selected_matches.splice(i, 1);
					}
				}
				return this.current.selected_matches.push(match);
			},
			setMatchFilter(option){
				this.current.matchFilter = option;
			},
			toggleCarouselOpen(){
				this.current.carouselSelectorOpen = !this.current.carouselSelectorOpen;
			},
			toggleScoreView(){
				this.current.showGamesWon = !this.current.showGamesWon;
			},
			setDialogMsg(msgType){
				this.dialogMsg = msgType;
			},
			resumePolling(){
				this.doNotPoll = false;
				alert('Resuming real-time updates.');
			},
			pollMatches() {
				var that = this;
				setTimeout(function() {
					if (that.doNotPoll){
						that.pollMatches();   // try again to detect when polling resumes
						return;
					}
					if (that.current.team) {
						that.loadMatchData(that.current.team, true, null);
					} else {
						that.pollMatches();
					}
				}, 8 * 1000);
			},
			pollMatchGames() {
				var that = this;
				setTimeout(function() {
					if (that.doNotPoll){
						that.pollMatchGames();   // try again to detect when polling resumes
						return;
					}
					if (that.current.match) {
						that.loadMatchGamesData(that.current.team, that.current.match, true);
					} else {
						that.pollMatchGames();
					}
				}, 8 * 1000);
			},
			activePage(page) {
				return (page === this.page);
			},
			loadTeamData(uid) {
				var that = this;
				that.teams = [];
				$.ajax({
					type: 'GET',
					dataType: 'json',
					url: API_URL + '/solo/get/v2/visible_teams',
					data: { uid: uid },
					timeout: TIMEOUT * 3,
					error(jqXHR, textStatus, errorThrown) {
						if (textStatus !== 'timeout') {
							alert("Network Error: " + errorThrown);
							$('#loading-match').hide();
							$('#loading-team').hide();
							eventHub.$emit('clear-match');
							that.page = 'device-select';
						}
					},
					success(data) {
						// coach doesn't need tournament info, so just grab app_data
						for (let i=0; i < data.length; i++) {
							that.teams.push(data[i].app_data);
						}
						$('#loading-match').hide();
						if (that.preload.device && that.preload.team){
							// load team specified in URL var if present in team list
							for (var i=0; i < that.teams.length; i++){
								if (that.teams[i].id === parseInt(that.preload.team)){
									eventHub.$emit('set-team', parseInt(that.preload.team));
								}
							}
						}
					},
				});
			},
			loadMatchData(team, polling, match_id) {
				var that = this;
				$.ajax({
					type: 'GET',
					dataType: 'json',
					url: API_URL + '/solo/get/visible_matches',
					data: {
						uid: that.current.device,
						team_id: team.id,
					},
					timeout: TIMEOUT * 3,
					error(jqXHR, textStatus, errorThrown) {
						if (!polling) {
							$('#loading-team').hide();
							$('#loading-match').hide();
							that.page = 'team-select';
							alert("Network Error: " + errorThrown);
						} else {
							$('#loading-team').hide();
							$('#loading-match').hide();
							that.doNotPoll = true;
							alert("Real-time updates halted due to lost connection. When you have a stable connection, select \"Resume Polling\" in the navbar menu to resume.");
						}
					},
					success(data) {
						var getLastDaysMatches = function(days){
							var matches = [];
							var today = new Date();
							var lastDate = new Date(0);
							_.forEach(data, function(match) {
								var date = new Date(match.start_dt ? match.start_dt : match.created);
								if (date < today && date >= lastDate) {
									matches.push(match);
									lastDate = new Date(date.getFullYear(), date.getMonth(), (date.getDate() - days), 0, 0, 0);
								}
							});
							return matches;
						};

						data.sort(function(a, b) {
							var keyA = new Date(a.start_dt ? a.start_dt : a.created);
							var keyB = new Date(b.start_dt ? b.start_dt : b.created);
							if (keyA < keyB) {
								return 1;
							}
							if (keyA > keyB) {
								return -1;
							}
							return 0;
						});
						that.matches = data;

						that.lastMatches = getLastDaysMatches(0);
						that.lastMatches2 = getLastDaysMatches(2);
						that.lastMatches3 = getLastDaysMatches(3);
						that.lastMatches4 = getLastDaysMatches(4);

						if (match_id) {
							var initial_match = _.find(that.matches, ['id', match_id]);
							if (initial_match) {
								eventHub.$emit('set-match', initial_match);
							}
						}
						$('#loading-team').hide();
						if (that.matches.length === 0) {
							that.page = 'team-select';
							eventHub.$emit('set-dialog-msg', 'no-matches');
						}
					},
					complete() {
						if (polling) {
							that.pollMatches();
						}
					}
				});
			},
			loadMatchGamesData(team, match, polling) {
				var that = this;
				$.ajax({
					type: 'GET',
					dataType: 'json',
					url: API_URL + '/solo/get/visible_match_games',
					data: {
						uid: that.current.device,
						match_ids: match.id,
					},
					timeout: TIMEOUT * 3,
					error(jqXHR, textStatus, errorThrown) {
						if (!polling) {
							eventHub.$emit('clear-match');
							$('#loading-team').hide();
							$('#loading-match').hide();
							alert("Network Error: " + errorThrown);
						} else {
							$('#loading-team').hide();
							$('#loading-match').hide();
							that.doNotPoll = true;
							alert("Real-time updates halted due to lost connection. When you have a stable connection, select \"Resume Polling\" in the navbar menu to resume.");
						}
					},
					success(data) {
						that.games = _.sortBy(data, ['num']);
						if (that.current.game) {
							that.current.game = _.find(data, ['id', that.current.game.id]);
						}
						$('#loading-match').hide();
					},
					complete() {
						if (polling) {
							that.pollMatchGames();
						}
					}
				});
			},
			loadMatchesGamesData(team, matches) {
				var matchIds = '';
				for (let i=0; i < matches.length; i++){
					if (i > 0){ matchIds += ','; }
					matchIds += matches[i].id;
				}

				var that = this;
				that.games = [];
				$.ajax({
					type: 'GET',
					dataType: 'json',
					url: API_URL + '/solo/get/visible_match_games',
					data: {
						uid: that.current.device,
						match_ids: matchIds,
					},
					timeout: TIMEOUT * 3,
					error(jqXHR, textStatus, errorThrown) {
						if (textStatus !== 'timeout') {
							alert("Network Error: " + errorThrown);
							$('#loading-match').hide();
							eventHub.$emit('clear-match');
						}
					},
					success(data) {
						var allGames = [];
						for (let i=0; i < data.length; i++){
							allGames = allGames.concat(data[i]);
						}
						that.games = allGames;
						$('#loading-match').hide();
					},
				});
			},
			loadAllMatchesGamesData(team) {
				var that = this;
				that.games = [];
				$.ajax({
					type: 'GET',
					dataType: 'json',
					url: API_URL + '/solo/get/visible_games',
					data: {
						uid: that.current.device,
						team_id: team.id,
					},
					timeout: TIMEOUT * 3,
					error(jqXHR, textStatus, errorThrown) {
						if (textStatus !== 'timeout') {
							alert("Network Error: " + errorThrown);
							$('#loading-match').hide();
							eventHub.$emit('clear-match');
						}
					},
					success(data) {
						that.games = data;
						$('#loading-match').hide();
					},
				});
			},
			loadInitialData() {
				var that = this;

				return $.ajax({
					type: 'GET',
					dataType: 'json',
					url: API_URL + '/coach/init',
					data: { email: Utils.getParameterByName('email') },
					timeout: TIMEOUT * 3,
					error(jqXHR, textStatus, errorThrown) {
						alert("Network Error: " + errorThrown);
						$('#initializing').hide();
						$(".no-visible-following").show();
					},
					success(data) {
						that.has_account = data.has_account;
						that.hidden_devices = data.hidden_devices;
						that.user_uid = data.user_uid;

					}
				});

			},
			loadDevicesData() {
				var that = this;

				$.ajax({
					type: 'GET',
					dataType: 'json',
					url: API_URL + '/user_team/get/devices',
					timeout: TIMEOUT * 3,
					error(jqXHR, textStatus, errorThrown) {
						alert("Network Error: " + errorThrown);
						$('#initializing').hide();
						$(".no-visible-following").show();
					},
					success(data) {
						var hiddenDevices = that.hidden_devices;
						if (that.preload.device){
							// ensure that devices that are hidden can still see themselves when accessing Coach from within app
							hiddenDevices = _.filter(hiddenDevices, function(device){ return device !== that.preload.device; });
						}
						that.devices = _.filter(data, function(o) { return o.app != 'solostatstouch' && (hiddenDevices.indexOf(o.uid) < 0); });
						if (that.devices.length === 0) {
							$("#initializing").hide();
							eventHub.$emit("set-dialog-msg", "no-subscription");
						} else if (that.preload.device){
							// load device specified in URL var if present in device list
							for (var i=0; i < that.devices.length; i++){
								if (that.devices[i].uid === that.preload.device){
									eventHub.$emit('set-device', that.preload.device);
								}
							}
						}
					}
				});
			}
		},
	});

	window.vue = vm;


	// UI handlers
	$('#myTabs a').click(function(e) {
		e.preventDefault();
		$(this).tab('show');
	});

	$(document).on('click', '.navbar-collapse.in', function(e) {
		if ($(e.target).is('a') && $(e.target).attr('class') != 'dropdown-toggle') {
			$(this).collapse('hide');
		}
	});

	Utils.sendAnalyticsPage(ANALYTICS_PAGE_ROOT + 'home');

	// Doesn't seem to be used anywhere - delete later
	//$('#actionToggle').click(function() {
	//  $(this).toggleClass("is-closed");
	//  $('#actionCount').slideToggle(100, "linear");
	//  return false;
	//});

});
